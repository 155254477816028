import { annualELectricityCostM } from "./Helpers";
import { Loans, IGSES } from "./StartProposalV2";

export const adjustedEPC = (otherValues) => {
  let EPC = getEPC(otherValues);
  let rebate = +otherValues.rebate || 0;
  console.log("here");
  console.log(EPC);
  console.log(rebate);
  console.log(otherValues);
  let adjustedEPC = EPC - rebate;

  return adjustedEPC;
};

export const getOffset = (otherValues) =>
  otherValues.production / otherValues.consumption;

export const getEPC = (otherValues) => {
  if (!otherValues) return 0;
  let ppw = +otherValues.ppW || 0;
  let panelWattage = +otherValues.panelWattage || 0;
  let panels = +otherValues.panels || 0;

  let EPC = ppw * panelWattage * panels;

  return EPC;
};

export const getSystemSize = (otherValues, allPanels) => {
  let pmax = getPanelPMax(otherValues, allPanels);

  let size = (pmax * +otherValues.panels) / 1000;

  return size;
};

export const getAnnualBill = (otherValues, monthValues, consumptionType) => {
  if (!otherValues) return 0;
  let utilRate = +otherValues.utilityRate;
  let currConsumption = +getTotalConsumption(
    otherValues,
    monthValues,
    consumptionType
  );
  let annBill;

  annBill = utilRate * currConsumption;

  return +annBill;
};

export const getMonthlyBill = (otherValues, monthValues, consumptionType) => {
  if (!otherValues) return 0;
  // console.log(otherValues, monthValues, consumptionType);
  let annBill = getAnnualBill(otherValues, monthValues, consumptionType);

  return +(annBill / 12).toFixed(2);
};

export const getLoanAmount = (otherValues, values, austinEnergy) => {
  if (!values) return 0;
  // console.log(otherValues);

  let adjEPC = adjustedEPC(otherValues);
  let EPC = getEPC(otherValues);

  let downPayment = +otherValues.downPayment;
  // let loanAmount = EPC - rebate - downPayment;

  if (values.State === "TX" && Loans.includes(otherValues.proposalType)) {
    if (austinEnergy.yes) {
      return EPC - downPayment;
    } else {
      return +adjEPC - downPayment;
    }
  }

  return +adjEPC - downPayment;
};

export const getSolarPayment = (otherValues, values, austinEnergy) => {
  if (!otherValues) return 0;
  if (!otherValues.finnancialPartner) return 0;
  if (!otherValues.loanTerm) return 0;

  let loanAmount = getLoanAmount(otherValues, values, austinEnergy);

  return +(loanAmount * otherValues.paymentFactor).toFixed(2);
};

export const getSolarPaymentSimple = (loanAmount, paymentFactor) =>
  +(loanAmount * paymentFactor).toFixed(2);

export const getSolarITC = (otherValues) => {
  if (!otherValues) return 0;
  let EPC = getEPC(otherValues);

  let rebate = +otherValues.rebate || 0;

  // const itc =
  //   otherValues.finnancialPartner === "Dividend"
  //     ? 0.3
  //     : otherValues.proposalType === "Cash"
  //     ? 0.3
  //     : 0.26;

  //everything has 30% itc
  const itc = 0.3;
  // console.log(`MB ITC: ${itc}`);

  let solarItc = (EPC - rebate) * itc;

  return +solarItc.toFixed(2);
};

export const getTotalConsumption = (
  otherValues,
  monthValues,
  consumptionType
) => {
  let monthCopy = [...monthValues];
  if (consumptionType === "Annual") {
    return +otherValues.consumption;
  }
  if (consumptionType === "Daily") {
    let total = monthCopy.reduce((prev, curr) => {
      prev += +curr.consumption * curr.days;
      return prev;
    }, 0);

    return total;
  }

  if (consumptionType === "Monthly") {
    let total = monthCopy.reduce((prev, curr) => {
      prev += +curr.consumption;

      return prev;
    }, 0);

    return total;
  }
};

export const getMonthlySolarBill = (otherValues) => {
  if (!otherValues) return 0;
  let prod = +otherValues.production || 0;
  let solarRatee = +otherValues.solarRate || 0;
  return (+(prod * solarRatee) / 12).toFixed(2);
};

export const utilityProductionDifference = (
  otherValues,
  monthValues,
  calledWith
) => {
  if (!monthValues) return 0;
  if (!otherValues || !otherValues.production || !otherValues.utilityRate)
    return 0;
  let consumption = getTotalConsumption(otherValues, monthValues) || 0;
  let currProduction = +otherValues.production || 0;
  let futureWholesaleRate = 0.035;
  let futureUtilRate = +otherValues.utilityRate || 0;
  if (Math.sign(consumption - currProduction) === -1) {
    return (consumption - currProduction) * futureWholesaleRate;
  } else {
    return (consumption - currProduction) * futureUtilRate;
  }
};

export const pvGen = (initialProposalData, otherValues) => {
  if (!initialProposalData)
    return annualELectricityCostM(otherValues.production / 12);
  let pvMonthly;
  if (initialProposalData.PVArray) {
    if (initialProposalData.production === otherValues.production) {
      pvMonthly = JSON.parse(initialProposalData.PVArray);

      // return initialProposalData.PVArray;
    } else {
      pvMonthly = annualELectricityCostM(otherValues.production / 12);
    }
  } else {
    pvMonthly = annualELectricityCostM(otherValues.production / 12);
  }

  return pvMonthly;
};

export const solarPlusUtil = (
  otherValues,
  values,
  austinEnergy,
  monthValues
) => {
  const { proposalType } = otherValues;
  // console.log("this hits", otherValues);
  if (!otherValues) return 0;
  let utilDiff = utilityProductionDifference(
    otherValues,
    monthValues,
    "solarPlusUtil"
  );

  let monthlySolar;
  // let solarAndUtil;
  if (Loans.includes(proposalType)) {
    monthlySolar = getSolarPayment(otherValues, values, austinEnergy);

    // solarAndUtil = +monthlySolar + +utilDiff / 12;

    return +monthlySolar + +utilDiff / 12;
  }
  if (IGSES.includes(proposalType)) {
    monthlySolar = getMonthlySolarBill(otherValues);
    const solarAndUtil = parseFloat(+monthlySolar + +utilDiff / 12);

    return solarAndUtil;
  }
};

export const getUtilityEscalator = (State) => {
  if (!State) return;
  if (State === "TX") return 2;
  if (State === "NY") return 2.9;
  else {
    return 3.9;
  }
};

export const suggestedBillSwapPPW = (values, otherValues) => {
  const statesToUse = ["TX", "FL", "PA"];

  const calcSuggested = (prodOrCon) => {
    return (
      (+prodOrCon * +otherValues.utilityRate) /
      12 /
      +otherValues.paymentFactor /
      (+otherValues.size * 1000)
    );
  };
  if (!values) return "";
  if (!otherValues) return "";
  if (!otherValues.production) return "";
  if (!otherValues.consumption) return "";

  if (!statesToUse.includes(values.State)) return "";
  if (!Loans.includes(otherValues.proposalType)) return "";
  if (otherValues.loanTerm !== 20) return "";
  else {
    if (otherValues.production <= otherValues.consumption) {
      return `Suggested Bill Swap PPW  $ ${calcSuggested(
        otherValues.production
      ).toFixed(2)}`;
    } else {
      return `Suggested Bill Swap PPW $ ${calcSuggested(
        otherValues.consumptionxx
      ).toFixed(2)}`;
    }
  }
};

export const getPanelPMax = (otherValues, allPanels) => {
  const pmax = otherValues
    ? allPanels[
        allPanels.findIndex((prod) => prod.name === otherValues.solarPanel)
      ].pmax
    : 0;

  return pmax;
};

// export const getInverterPMax = otherValues => {
//   let pmax =
//     otherValues.solarPanel &&
//     inverters[inverters.findIndex(prod => prod.name === otherValues.inverter)]
//       .pmax;

//   return pmax;
// };

export const getBatterySystemSize = (batteryQty) => +batteryQty * 3.36;

export const getBatteryCost = (batterySystemSize, ppw, wattage) =>
  batterySystemSize * +ppw * +wattage;

export const getBatteryMonthlyPayment = (batteryCost, paymentFactor) =>
  batteryCost * +paymentFactor;

export const getSolarPlusBatteryMonthlyPayment = (
  batteryMonthly,
  solarMonthly
) => +batteryMonthly + +solarMonthly;

export const getGeneracLoanValues = (
  batteryQty,
  ppw,
  wattage,
  paymentFactor,
  batteryCost,
  solarLoanAmount,
  enphaseRebate,
  batteryRebate
) => {
  const batterySystemSize = getBatterySystemSize(batteryQty);
  // const batteryCost = getBatteryCost(batterySystemSize, ppw, wattage);
  const monthlyBatteryPayment = getBatteryMonthlyPayment(
    batteryCost,
    paymentFactor
  );

  const monthlySolarPayment = getSolarPaymentSimple(
    solarLoanAmount,
    paymentFactor
  );

  const solarPlusBatteryCost = +solarLoanAmount + +batteryCost;
  const solarPlusBatteryMonthlyPayment =
    +monthlySolarPayment + +monthlyBatteryPayment;

  return {
    enphaseRebate: +enphaseRebate,
    batteryRebate: +batteryRebate,
    batteryModuleQuantity: +batteryQty,
    batterySystemSize,
    batteryCost: +batteryCost,
    solarPlusBatteryCost,
    monthlyBatteryPayment,
    solarPlusBatteryMonthlyPayment,
  };
};

export const ifAnnual = (otherValues, monthValues, consumptionType) => {
  if (consumptionType === "Annual") {
    return JSON.stringify(annualELectricityCostM(otherValues.consumption / 12));
  }

  if (consumptionType === "Monthly") {
    let annualElectricityCostArr = monthValues.reduce((months, month) => {
      months.push(month.consumption);
      return months;
    }, []);
    return JSON.stringify(annualElectricityCostArr);
  }

  if (consumptionType === "Daily") {
    let annualElectricityCostArr = monthValues.reduce((months, month) => {
      months.push(month.consumption * month.days);
      return months;
    }, []);
    return JSON.stringify(annualElectricityCostArr);
  }
};
