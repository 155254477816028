import React, { useState, useContext } from "react";
import ModalComp from "../ConsumptionType/Modal/Modal";
import { Context } from "../../Context/ProposalContext";

import { Public, Clear, FileCopy, CancelOutlined } from "@material-ui/icons";

import styled from "styled-components";
//import SubmittingLoader from "../SubmittingLoader";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_PROPOSAL } from "../../utils/QLMutations";
import { USER_BY_EMAIL_QUERY } from "../../utils/GQL/QLQueries";

const WrapperStyles = styled.div`
  display: grid;
  grid-template-rows: 70px 1fr;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .modal-header {
    display: grid;
    grid-template-columns: 1fr 60px;
    justify-items: center;
    align-items: center;
  }

  button {
    border: solid 2px #3db0ef;
    background: white;
    border-radius: 4px;
    padding: 14px;
    color: #3db0ef;
    font-size: 1.5rem;
    margin-right: 8px;
    width: fit-content;
    align-self: center;
    :hover {
      cursor: pointer;
      box-shadow: 0 0.4rem 1.4rem 0 rgba(86, 185, 235, 0.5);
      transform: translateY(-0.1rem);
      transition: transform 150ms;
    }

    [disabled]:hover {
      cursor: not-allowed;
      box-shadow: none;
      transform: none;
    }
  }

  .completed-proposal {
    display: grid;
    grid-auto-rows: 50px;
    width: 94%;
    margin: 0 auto;
    grid-gap: 10px;

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 2fr;
      grid-gap: 10px;
      p {
        margin: 0;
      }
    }
    .col {
      display: grid;
      justify-items: center;
      border: solid 2px #3db0ef;
      align-items: center;
      border-radius: 2px;
    }
  }

  .preview-buttons {
    display: flex;
    justify-content: center;
  }
`;

const CompletedProposal = ({ modalState, setModalState }) => {
  const {
    initialFormValues,
    proposalPreview,
    otherValues,
    //setProposalPreview,
    userValues: { userName },
  } = useContext(Context);
  const [deleteProposal] = useMutation(DELETE_PROPOSAL);

  const [deleteBool, setDeleteBool] = useState(false);

  const onCloseModal = () => {
    setDeleteBool(false);
    setModalState("");
  };

  const onDeleteProposal = async () => {
    const { completedProposalId, proposalId, userId } = proposalPreview;

    try {
      // eslint-disable-next-line
      const deletedRes = await deleteProposal({
        variables: {
          input: {
            completedProposalId,
            proposalId,
            userId,
          },
        },
        refetchQueries: [
          {
            query: USER_BY_EMAIL_QUERY,

            variables: {
              proposalAgentEmail: userName,
            },
          },
        ],
      });
      setModalState("deleted");
    } catch (e) {
      console.log(e);
    }
  };

  const ModalWrapper = ({ children }) => (
    <WrapperStyles>
      <div className="modal-header">
        <h2> Submitting Proposal </h2>
        <Clear onClick={onCloseModal} />
      </div>
      <div className="content">{children}</div>
    </WrapperStyles>
  );

  const ProposalPreview = ({ digitalUrl, pdfUrl }) => {
    return (
      <div className="completed-proposal">
        <div className="row header">
          <div className="col header">
            <p> Type </p>
          </div>
          <div className="col header">
            <p> Open Link </p>
          </div>
          <div className="col header">
            <p> Copy Link </p>
          </div>
          <div className="col header">
            <p> URL </p>
          </div>
        </div>
        {digitalUrl !== "none" && (
          <div className="row">
            <div className="col ">
              <p> Digital </p>
            </div>
            <div className="col">
              <Link url={digitalUrl} />
            </div>
            <div className="col">
              <Copy url={digitalUrl} />
            </div>
            <div style={{ overflow: "auto" }} className="col">
              <p>{digitalUrl === "error" ? "An Error Occcurd" : digitalUrl}</p>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <p> {`PDF`} </p>
          </div>
          <div className="col">
            <Link url={pdfUrl} />
          </div>
          <div className="col">
            <Copy url={pdfUrl} />
          </div>
          <div style={{ overflow: "auto" }} className="col">
            <p> {pdfUrl} </p>
          </div>
        </div>
      </div>
    );
  };

  const Link = ({ url }) =>
    url === "error" ? (
      <CancelOutlined style={{ color: "red" }} />
    ) : (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <Public />
      </a>
    );

  const Copy = ({ url }) =>
    url === "error" ? (
      <CancelOutlined style={{ color: "red" }} />
    ) : (
      <FileCopy
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
      />
    );

  if (modalState === "") return null;
  if (modalState === "submitted")
    return (
      <ModalComp setModalState={setModalState} open={true} width={"60%"}>
        <ModalWrapper>
          <h1>Please Wait While We Submit Your Proposal</h1>
        </ModalWrapper>
        {/* <SubmittingLoader /> */}
      </ModalComp>
    );

  if (modalState === "revision")
    return (
      <ModalComp setModalState={setModalState} open={true} width={"60%"}>
        <ModalWrapper>
          <h1>The Proposal PDF was sucessfully generated</h1>
          <ProposalPreview
            digitalUrl={"none"}
            pdfUrl={proposalPreview.pdfUrl}
          />
          <button onClick={() => onCloseModal()}>Close</button>
        </ModalWrapper>
      </ModalComp>
    );

  if (modalState === "completedproposal")
    return (
      <ModalComp setModalState={setModalState} open={true} width={"60%"}>
        <ModalWrapper>
          <h1>
            {" "}
            {deleteBool
              ? "Are you sure you want to delete ? This action is final and cannot be undone"
              : "The Proposal PDF was sucessfully generated"}
          </h1>
          <ProposalPreview
            digitalUrl={proposalPreview.digitalUrl}
            pdfUrl={proposalPreview.pdfUrl}
          />
          <div className="preview-buttons">
            {deleteBool ? (
              <>
                <button onClick={() => onDeleteProposal()}>Yes</button>
                <button onClick={() => setDeleteBool(false)}>No</button>
              </>
            ) : (
              <>
                <button onClick={() => onCloseModal()}>Close</button>
                <button onClick={() => setDeleteBool(true)}>
                  Delete Proposal
                </button>
              </>
            )}
          </div>
        </ModalWrapper>
      </ModalComp>
    );

  if (modalState === "pdfonlyfail")
    return (
      <ModalComp setModalState={setModalState} open={true} width={"60%"}>
        <ModalWrapper>
          <h1>
            {" "}
            {deleteBool
              ? "Are you sure you want to delete ? This action is final and cannot be undone"
              : "The Digital Proposal was sucessfully generated, though there was an issue with the PDF "}
          </h1>
          <ProposalPreview
            digitalUrl={proposalPreview.digitalUrl}
            pdfUrl={proposalPreview.pdfUrl}
          />
          <div className="preview-buttons">
            {deleteBool ? (
              <>
                <button onClick={() => onDeleteProposal()}>Yes</button>
                <button onClick={() => setDeleteBool(false)}>No</button>
              </>
            ) : (
              <>
                <button onClick={() => onCloseModal()}>Close</button>
                <button onClick={() => setDeleteBool(true)}>
                  Delete Proposal
                </button>
              </>
            )}
          </div>
        </ModalWrapper>
      </ModalComp>
    );

  if (modalState === "digitalerror")
    return (
      <ModalComp open={true} width={"60%"}>
        <ModalWrapper>
          <h1>
            The Proposal PDF was sucessfully generated, however there was an
            issue with with digital one
          </h1>
          <ProposalPreview
            digitalUrl={"error"}
            pdfUrl={proposalPreview.pdfUrl}
          />
          <button onClick={() => onCloseModal()}>Close</button>
        </ModalWrapper>
      </ModalComp>
    );

  if (modalState === "failed")
    return (
      <ModalComp open={true} width={"60%"}>
        <ModalWrapper>
          <h1>
            An Issue occured with generating the Proposal Please Contact Support
            or Try again
          </h1>

          <button onClick={() => onCloseModal()}>Close</button>
        </ModalWrapper>
      </ModalComp>
    );

  if (modalState === "deleted")
    return (
      <ModalComp open={true} width={"60%"}>
        <ModalWrapper>
          <h1>
            The Proposal Was deleted and the request was returned to your queue
          </h1>

          <button onClick={() => onCloseModal()}>Close</button>
        </ModalWrapper>
      </ModalComp>
    );
};

export default CompletedProposal;
