import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { cleanNumbers, pullEmpties, deleteKeys } from "./Helpers";
import { MenuItem, InputLabel } from "@material-ui/core";
import { USER_BY_EMAIL_QUERY } from "../../utils/GQL/QLQueries";
import { validateOtherValues, validateValues } from "./validate";
import {
  StyledCon,
  StyledFormCon,
  FormItself,
  FormControlStyles,
  TextFieldStyles,
  SelectStyles,
  SubmissionDiv,
  ButtonCon,
} from "./styles";
import CurrProposalDataHeader from "../../components/CurrProposalHeader/CurrProposalDataHeader";
import ProposalImages from "../../components/ProposalImages/ProposalImages";
import {
  getMonthlyBill,
  pvGen,
  getOffset,
  getGeneracLoanValues,
} from "./Calculations";
import UserCallToAction from "../../components/UserCallToAction";
import Loan from "../../containers/Loan/Loan";
import Cash from "../../containers/Cash/Cash";

import IGSPPA from "../../containers/IGSPPA/IGSPPA";
import { client } from "../../App";
import { ALL_PRODUCTS_QUERY } from "../../utils/ProductsQueries";
import { COMPLETE_PROPOSAL_MUTATION } from "../../utils/QLMutations";
import {
  insertSpaceAtCaps,
  prepPDFData,
  addWorkVals,
  generatePDF,
} from "./utils";
// eslint-disable-next-line
import { sendEmail2, postSlackError } from "../../utils/API";
import { Context } from "../../Context/ProposalContext";
import CompletedProposal from "../../components/CompletedProposal/CompletedProposal";
import { BatteryContext } from "../../Context/BatteryContext";
import { PostDigitalFail } from "./slackError";
import Axios from "axios";
import { DecoTechContext } from "../../Context/DecoTechContext";

export let needsToBeInt = [
  "inverterWattage",
  "panelWattage",
  "production",
  "downPayment",
  "rebate",
  "utilityRate",
  "panels",
  "ppW",
  "solarRate",
  "solarEscalator",
];
export let deleteThese = [
  "panelImageUrl",
  "designImageUpload",
  "adjustedEPC",
  "monthlyBill",
  "srecs",
];

export const Loans = ["Loan", "GeneracLoan", "JEALoan", "DecoLoan"];
export const Cashes = ["Cash", "DecoCash"];
export const IGSES = ["IGSPPA", "IGSLEASE", "SUNRUN", "MSES"];
export const Generacs = ["GeneracLoan", "JEALoan"];
const StartProposalV2 = () => {
  const localProd = "https://proposalgen2.momentumsolar.app";

  const [modalState, setModalState] = useState("");
  const {
    initialProposalData,
    initialFormValues,
    setInitialValues,
    otherValues,
    setOtherValues,
    userValues: { userName, name },
    austinEnergy,
    monthValues,
    appPermissions: { _id },
    revision,
    setProposalPreview,
    useOriginal,
    consumptionType,
    fID,
    setUtilityList,
    utilitySubmit,
    demoid,
    setDemoId,
  } = useContext(Context);
  const { decoForm } = useContext(DecoTechContext);

  const { generacForm, setGeneracForm } = useContext(BatteryContext);

  const { proposalType } = otherValues;

  const resetWork = () => {
    setOtherValues({
      ...otherValues,
      additionalwork: [
        {
          type: "tree",
          notes: "",
          img: "",
          filePreview: "",
          required: "",
          estimatedCost: "",
        },
        {
          type: "trench",
          notes: "",
          img: "",
          filePreview: "",
          required: "",
          estimatedCost: "",
        },
      ],
    });
  };

  const clearGenerac = () => {
    setGeneracForm({
      batteryModuleQty: "",
      batteryCost: "",
    });
  };

  // causing issue

  const { allProducts } = client.readQuery({
    query: ALL_PRODUCTS_QUERY,
  });
  let { allPanels, inverters } = allProducts;
  allPanels = allPanels.filter((panel) => panel.active);
  const [completeProposal] = useMutation(COMPLETE_PROPOSAL_MUTATION);

  const revisionValues = { ...initialProposalData };

  const [next, setNext] = useState(false);

  const [message, setMessage] = useState("");

  // helper methods.
  const changeInput = (e) => {
    setInitialValues({
      ...initialFormValues,
      [e.target.name]: e.target.value,
    });
  };

  const validatePageTwo = (argsmessage) => {
    setMessage(argsmessage);
    setTimeout(() => {
      setMessage("");
    }, 1500);
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    e.persist();
    //hit api
    console.log("look for opp");
    console.log(otherValues);
    console.log(initialFormValues);
    if (!otherValues.panels) {
      validatePageTwo("Please enter a Panel Quantity");
      return;
    }
    console.log(otherValues);
    if (otherValues.msesPrime === "" && otherValues.proposalType === "MSES") {
      validatePageTwo("Please select if this is MSES Prime");
      return;
    }
    if (
      otherValues.proposalType === "DecoCash" ||
      otherValues.proposalType === "DecoLoan"
    ) {
      if (+decoForm.roof_squares === 0) {
        validatePageTwo("Please enter a roof square count greater then zero!");
        return;
      }

      if (+decoForm.roof_cost === 0) {
        validatePageTwo("Please enter a roof cost greater then zero!");
        return;
      }
    }

    //Says this nested block is redunant try to fix
    // eslint-disable-next-line
    if (useOriginal) {
      //Says this nested block is redunant try to fix
      // eslint-disable-next-line
      if (!otherValues.designUrl) {
        // eslint-disable-next-line
        {
          validatePageTwo(
            "There isn't an Original Image to Use, Upload One Instead"
          );
          return;
        }
      }
    }
    //Says this nested block is redunant try to fix
    // eslint-disable-next-line
    if (!useOriginal) {
      //Says this nested block is redunant try to fix
      // eslint-disable-next-line
      if (!otherValues.designImageUpload) {
        // eslint-disable-next-line
        {
          validatePageTwo("Please Upload an Image");
          return;
        }
      }
    }

    if (!otherValues.production) {
      validatePageTwo("Please enter value for Production");
      return;
    }
    if (!otherValues.ppW) {
      validatePageTwo("Please enter PPW");
      return;
    }

    if (consumptionType === "Annual") {
      if (!otherValues.consumption) {
        validatePageTwo("Please enter value for Consumption");
        return;
      }
    }

    if (consumptionType !== "Annual") {
      let thing = monthValues.filter((month) => month.consumption === "");

      if (thing.length > 0) {
        validatePageTwo(
          `Please enter value for ${thing[0].month} Consumption -- Not Ann`
        );
        return;
      }
    }

    if (!otherValues.utilityRate) {
      validatePageTwo("Please enter a Utility Rate!");
      return;
    }

    if (Loans.includes(proposalType) && !otherValues.loanTerm) {
      validatePageTwo("Please select a Loan Term");
      return;
    }

    if (otherValues.proposalReason === "") {
      validatePageTwo("Proposal Reason Required a look above!");
      return;
    }
    if (
      otherValues.proposalReason.includes("Design Change Requested") &&
      otherValues.proposalSubreason === ""
    ) {
      validatePageTwo("Proposal Subreason required!");
      return;
    }

    if (otherValues.additionalwork[0].required === "") {
      validatePageTwo(`Please Select if Tree Work is required or not`);
      return;
    }
    if (otherValues.additionalwork[1].required === "") {
      validatePageTwo(`Please Select if Trench Work is required or not`);
      return;
    }

    if (otherValues.additionalwork[0].required) {
      if (!otherValues.additionalwork[0].img) {
        validatePageTwo(`Please Upload A Tree Work Image`);
        return;
      }
      if (
        !otherValues.additionalwork[0].treeNum ||
        +otherValues.additionalwork[0].treeNum === 0
      ) {
        validatePageTwo(`Please enter a tree number`);
        return;
      }
      if (!otherValues.additionalwork[0].remover) {
        validatePageTwo(`Please choose who is to remove trees`);
        return;
      }

      if (!otherValues.additionalwork[0].notes) {
        validatePageTwo(`Please Enter Tree Work Notes`);
        return;
      }
      if (!otherValues.additionalwork[0].estimatedCost) {
        validatePageTwo(`Please an Estimated Cost for the Tree Work`);
        return;
      }
    }
    if (otherValues.additionalwork[1].required) {
      if (!otherValues.additionalwork[1].img) {
        validatePageTwo(`Please Upload A Trench Work Image`);
        return;
      }
      if (!otherValues.additionalwork[1].notes) {
        validatePageTwo(`Please Enter Trench Work Notes`);
        return;
      }
      if (!otherValues.additionalwork[1].estimatedCost) {
        validatePageTwo(`Please an Estimated Cost for the Trench Work`);
        return;
      }
    }

    // From Here On Can Later be it's own FN call
    setModalState("submitted");
    const body = {
      demoid: demoid,
      fid: fID,
    };
    if (body.fid !== "" && body.demoid !== "") {
      //do post
      await Axios.post(
        `https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/addpartnerid`,
        body
      );
      // alert(JSON.stringify(body, null, 2));
    }

    const utilBody = {
      demoid: demoid,
      utilityid: utilitySubmit.iis_utilityid ? utilitySubmit.iis_utilityid : "",
    };
    if (utilBody.demoid !== "" && utilBody.utilityid !== "") {
      await Axios.post(
        `https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/addutility`,
        utilBody
      );
    }

    let pdfdata = prepPDFData(
      otherValues,
      initialFormValues,
      monthValues,
      consumptionType,
      initialProposalData,
      austinEnergy,
      allPanels
    );

    let dbInfo = {
      ...pdfdata,
      preparerEmail: userName,
      preparerName: name,
    };

    if (revision) {
      let pdf = await generatePDF(pdfdata, revisionValues, revision, localProd);
      let pdfUrlLocal = pdf.data;
      setModalState("revision");
      setProposalPreview((prevState) => ({
        ...prevState,
        pdfUrl: pdfUrlLocal,
      }));

      openWindowWithPDF(pdfUrlLocal);
    } else {
      let pdfUrl = "";
      // Proceed to saving to GQL
      let completedDigitalRes = await saveToDB(dbInfo, pdfUrl);
      const completedProposalId =
        completedDigitalRes && completedDigitalRes.data.completeProposal.id;
      const digitalUrl = `https://proposalview.momentumsolar.app/${completedProposalId}`;
      //post to update pdf url
      pdfUrl = `https://proposal.momentumsolar.app/onepage/${completedProposalId}`;

      //@TO DO
      await Axios.post(
        "https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/add_pdf_url",
        {
          url: `https://proposal.momentumsolar.app/onepage/${completedProposalId}`,
          guid: `${completedProposalId}`,
          timerequested: otherValues.timerequested,
        }
      );
      let send_body = {
        proposalSubreason: otherValues.proposalSubreason,
        guid: `${completedProposalId}`,
      };
      await Axios.post(
        `https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/add_new_keys`,
        send_body
      );

      //if we got some deco data
      console.log("here is deco check");
      console.log(otherValues);
      console.log(initialFormValues);

      if (
        otherValues.proposalType === "DecoCash" ||
        otherValues.proposalType === "DecoLoan"
      ) {
        let send_body = {
          ...decoForm,
          proposalSubreason: otherValues.proposalSubreason,
          guid: `${completedProposalId}`,
        };
        await Axios.post(
          `https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/add_new_keys`,
          send_body
        );
      }
      let newDigital =
        otherValues.proposalType === "DecoCash" ||
        otherValues.proposalType === "DecoLoan"
          ? "none"
          : digitalUrl;
      // const digitalUrl = `https://dev.momentumsolar.app/home/${completedProposalId}`;
      console.log("here is boop");
      console.log(newDigital);
      setProposalPreview((prevState) => ({
        ...prevState,
        digitalUrl: newDigital,
        pdfUrl,
        completedProposalId,
      }));
      console.log(completedDigitalRes);
    }
  };

  const generateGeneracSystemValues = (data, generacFormData) => {
    console.log(data, generacFormData);
    const { ppw, panelWattage, paymentFactor, loanAmount } = data;
    const { batteryModuleQty, batteryCost, enphaseRebate, batteryRebate } =
      generacFormData;

    let GeneracSystemInput;
    if (proposalType === "GeneracLoan" || proposalType === "JEALoan") {
      GeneracSystemInput = {
        ...getGeneracLoanValues(
          batteryModuleQty,
          ppw,
          panelWattage,
          paymentFactor,
          batteryCost,
          loanAmount,
          enphaseRebate,
          batteryRebate
        ),
      };
    } else {
      GeneracSystemInput = null;
    }

    return GeneracSystemInput;
  };

  const saveToDB = async (data, pdfUrl) => {
    setProposalPreview((prevState) => ({
      ...prevState,
      userId: _id,
      proposalId: initialProposalData ? initialProposalData.id : null,
    }));

    try {
      let initialDataCopy = { ...initialProposalData };
      delete initialDataCopy.id;
      let CompletedProposalInput = {
        ...cleanNumbers(
          deleteKeys(pullEmpties(data), deleteThese),
          needsToBeInt
        ),

        additionalwork: addWorkVals(data.additionalwork),
        pvGenArray: JSON.stringify(pvGen(initialProposalData, otherValues)),
        pdfUrl,
        annualPVDeg: 0.005,
        offset: getOffset(otherValues),
        oldMonthlyBill: getMonthlyBill(
          otherValues,
          monthValues,
          consumptionType
        ),
        ...initialDataCopy,
      };

      let variables = {
        CompletedProposalInput,

        user: _id,
        GeneracSystemInput: generateGeneracSystemValues(data, generacForm),
      };

      if (initialProposalData && initialProposalData.id !== "") {
        variables = {
          ...variables,

          proposalId: initialProposalData.id,
        };
      }
      variables.CompletedProposalInput.panels = +data.panels;
      variables.CompletedProposalInput.production = +data.production;
      variables.CompletedProposalInput.designUrl = otherValues.designUrl;
      variables.CompletedProposalInput.size = data.size;
      variables.CompletedProposalInput.msesPrime =
        otherValues.msesPrime === "" || otherValues.msesPrime === false
          ? false
          : true;
      delete variables.CompletedProposalInput.timerequested;
      console.log("------MB_NEWPROP------");

      delete variables.CompletedProposalInput.proposalSubreason;
      console.log(variables);
      console.log(otherValues);
      console.log("------MB_NEWPROP------");
      const completedres = await completeProposal({
        variables,

        refetchQueries: [
          {
            query: USER_BY_EMAIL_QUERY,
            variables: { proposalAgentEmail: userName },
          },
        ],
      });
      setModalState("completedproposal");
      const completedProposalId = completedres.data.completeProposal.id;
      const digitalUrl = `https://proposalview.momentumsolar.app/${completedProposalId}`;
      pdfUrl = `https://proposal.momentumsolar.app/onepage/${completedProposalId}`;
      // const digitalUrl = `https://dev.momentumsolar.app/home/${completedProposalId}`;
      setProposalPreview((prevState) => ({
        ...prevState,
        pdfUrl,
        digitalUrl,
        completedProposalId,
      }));
      sendEmail2(CompletedProposalInput, { userName, name }, true);
      resetWork();
      setOtherValues((prevState) => ({
        ...prevState,
        proposalReason: "",
      }));
      clearGenerac();
      return completedres;
    } catch (error) {
      // Send Slack / Email if fails also infrom user
      PostDigitalFail(error, data.oppnum, data.preparerEmail);
      setModalState("digitalerror");
      console.log(error);
      return error;
    }
  };

  const openWindowWithPDF = (pdfUrl) => window.open(pdfUrl, "_newtab");

  const checkNextStep = (e) => {
    e.persist();
    e.preventDefault();
    const { otherValuesStatus, otherValuesMsg } = validateOtherValues(
      otherValues,
      initialFormValues
    );
    const { initialValuesStatus, initialOtherValuesMsg } =
      validateValues(initialFormValues);

    if (!initialValuesStatus || !otherValuesStatus) {
      setMessage(initialOtherValuesMsg || otherValuesMsg);
      setTimeout(() => {
        setMessage("");
      }, 2000);
      return;
    }

    //we want to hit api to get utilities
    Axios.post(
      "https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/utiliteslist"
    )
      .then((v) => {
        console.log(v.data);
        setUtilityList(v.data);
        setNext(true);
      })
      .catch((e) => {
        setNext(true);
      });
  };

  const runOppLokup = (e) => {
    Axios.get(
      `https://px2xb0ipp3.execute-api.us-east-1.amazonaws.com/api/searchopn/OP-${initialFormValues.oppnum.replace(
        /\D/g,
        ""
      )}`
    )
      .then((v) => {
        if (v.data.length > 0) {
          let valid = v.data[0];
          let household = valid.mts_Household;
          let person = valid.mts_PrimaryContact.fullname;
          let updated_form = {
            ...initialFormValues,
            State:
              household["iis_state@OData.Community.Display.V1.FormattedValue"],
            Address: household.address1_line1,
            City: household.address1_city,
            Zip: household.address1_postalcode,
            CustomerName: person,
          };
          setInitialValues(updated_form);
          setDemoId(valid.mts_demoappointmentid);
        }
      })
      .catch((e) => {});
  };

  const onClickBack = (e) => {
    e.preventDefault();
    setOtherValues({
      ...otherValues,
      loanTerm: "",
      interest: "",
      msesPrime: "",
      paymentFactor: "",
      rebate: "",
      // proposalReason: "",
    });
    clearGenerac();

    setNext(false);
  };

  const handleDropChange2 = (e) => {
    setInitialValues({
      ...initialFormValues,
      [e.target.name]: e.target.value,
    });
    // setOtherValues(initialOtherValues);
    setOtherValues({ ...otherValues });
  };

  const handleDropChange = (e) => {
    let product;
    if (e.target.name === "solarPanel") {
      product = allPanels.find((prod) => prod.name === e.target.value);

      setOtherValues({
        ...otherValues,
        solarPanel: e.target.value,
        panelWattage: `${product.pmax}`,
        panelImageUrl: product.productImage && product.productImage.panelUrl,
        panelInfo: product && product._id,
      });
    } else if (e.target.name === "proposalType") {
      console.log("hits first type conditional");

      if (Generacs.includes(e.target.value)) {
        console.log("hits if type of generac confitional");
        product = inverters.find(
          (prod) => prod.name === "Enphase IQ7-60-2-US-208"
        );
        setOtherValues({
          ...otherValues,
          proposalType: e.target.value,

          inverter: product.name,
          inverterWattage: `${product.pmax}`,
        });
        return;
      } else {
        console.log("not generac conditional");
        setOtherValues({
          ...otherValues,
          [e.target.name]: e.target.value,
        });
        return;
      }
    } else if (e.target.name === "inverter") {
      product = inverters.find((prod) => prod.name === e.target.value);
      setOtherValues({
        ...otherValues,
        inverter: e.target.value,
        inverterWattage: `${product.pmax}`,
      });
    } else {
      console.log("is htis also hitting ?");
      setOtherValues({
        ...otherValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const states = [
    "GA",
    "NJ",
    "NY",
    "PA",
    "CT",
    "FL",
    "CA",
    "TX",
    "NV",
    "AZ",
    "MA",
  ];

  const notTX = [
    { value: "IGSPPA", name: "IGS PPA" },
    { value: "IGSLEASE", name: "IGS Lease" },
    { value: "MSES", name: "MSES" },
  ];

  const ProposalFormToReturn = () => {
    let Tag;

    const { proposalType } = otherValues;
    if (Loans.includes(proposalType)) Tag = Loan;
    if (Cashes.includes(proposalType)) Tag = Cash;
    if (IGSES.includes(proposalType)) Tag = IGSPPA;

    return (
      <Tag
        State={initialFormValues.State}
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    );
  };

  // dom rendering.
  return (
    <>
      <StyledCon>
        <CompletedProposal
          modalState={modalState}
          setModalState={setModalState}
        />
        <CurrProposalDataHeader />

        {initialProposalData &&
          initialProposalData.pimg &&
          initialProposalData.pimg.length > 0 && <ProposalImages />}

        <StyledFormCon>
          <form onSubmit={(e) => onSubmitForm(e)}>
            {!next ? (
              <FormItself>
                {Object.keys(initialFormValues)
                  .filter(
                    (key1) =>
                      key1 !== "State" &&
                      key1 !== "FinancerId" &&
                      key1 !== "utility"
                  )
                  .map((key) =>
                    key === "oppnum" ? (
                      <TextFieldStyles
                        name={key}
                        onBlur={runOppLokup}
                        value={initialFormValues[key]}
                        key={key}
                        onChange={(e) => changeInput(e)}
                        id={`filled-${key}`}
                        label={insertSpaceAtCaps(key)}
                        autoComplete="off"
                        error={
                          !initialFormValues[key] && key !== "FinancerId"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <TextFieldStyles
                        name={key}
                        value={initialFormValues[key]}
                        key={key}
                        onChange={(e) => changeInput(e)}
                        id={`filled-${key}`}
                        label={insertSpaceAtCaps(key)}
                        autoComplete="off"
                        error={
                          !initialFormValues[key] && key !== "FinancerId"
                            ? true
                            : false
                        }
                      />
                    )
                  )}
                <FormControlStyles>
                  <InputLabel> State </InputLabel>
                  <SelectStyles
                    value={initialFormValues.State}
                    onChange={(e) => handleDropChange2(e)}
                    name={"State"}
                  >
                    {states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </SelectStyles>
                </FormControlStyles>

                {/* proposal types */}
                <FormControlStyles>
                  <InputLabel> Proposal Type </InputLabel>
                  <SelectStyles
                    value={otherValues.proposalType}
                    onChange={(e) => handleDropChange(e)}
                    name={"proposalType"}
                  >
                    <MenuItem value={"Cash"}>Cash</MenuItem>
                    <MenuItem value={"Loan"}>Loan</MenuItem>
                    <MenuItem value={"GeneracLoan"}>
                      Enphase Solar + Storage Loan
                    </MenuItem>
                    <MenuItem value={"DecoLoan"}>Decotech Loan</MenuItem>
                    {/* <MenuItem value={"DecoCash"}>Decotech Cash</MenuItem> */}

                    {(initialFormValues.State === "FL" ||
                      initialFormValues.State === "GA" ||
                      initialFormValues.State === "TX") && (
                      <MenuItem value={"JEALoan"}>
                        Solar + Storage Loan (No Net Meter)
                        {/* {initialFormValues.State === "GA"
                          ? "GA  Storage Loan"
                          : "JEA Solar + Storage Loan"} */}
                      </MenuItem>
                    )}
                    {initialFormValues.State !== "TX" &&
                      notTX.map(({ name, value }) => (
                        <MenuItem key={value} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                  </SelectStyles>
                </FormControlStyles>
                {/* If IGS */}
                {/* {(otherValues.proposalType === "IGSPPA" ||
                  otherValues.proposalType === "IGSLEASE") && (
                  <FormControlStyles>
                    <InputLabel> IGS Type </InputLabel>
                    <SelectStyles
                      value={otherValues.proposalType}
                      onChange={(e) => handleDropChange(e)}
                      name={"proposalType"}
                    >
                      <MenuItem value={"IGSPPA"}>PPA</MenuItem>
                      <MenuItem value={"IGSLEASE"}>Loan</MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )} */}
                {Loans.includes(otherValues.proposalType) && (
                  // Proposal Fiannacial Partner
                  // Render this dropdown if loan selected
                  <FormControlStyles>
                    <InputLabel> Select Finnancial Partner </InputLabel>
                    <SelectStyles
                      value={otherValues.finnancialPartner}
                      onChange={(e) => handleDropChange(e)}
                      name={"finnancialPartner"}
                    >
                      {/* <MenuItem value={"GreenSky"}>Green Sky</MenuItem> */}

                      <MenuItem value={"Dividend"}>Dividend</MenuItem>

                      <MenuItem value={"Sunlight"}>Sunlight</MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )}

                {
                  // Panel Dropdown
                }
                {initialFormValues.State === "MA" && (
                  <FormControlStyles>
                    <InputLabel> Select Utility </InputLabel>
                    <SelectStyles
                      value={initialFormValues.utility}
                      onChange={(e) => handleDropChange2(e)}
                      name={"utility"}
                    >
                      <MenuItem value={"Eversource"}>Eversource</MenuItem>

                      <MenuItem value={"NationalGrid"}>National Grid</MenuItem>

                      <MenuItem value={"Unitil"}>Unitil</MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )}
                {initialFormValues.State === "CT" && (
                  <FormControlStyles>
                    <InputLabel> Select Utility </InputLabel>
                    <SelectStyles
                      value={initialFormValues.utility}
                      onChange={(e) => handleDropChange2(e)}
                      name={"utility"}
                    >
                      <MenuItem value={"Eversource"}>Eversource</MenuItem>

                      <MenuItem value={"UnitedIlluminating"}>
                        United Illunminating
                      </MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )}
                {initialFormValues.State === "NY" && (
                  <FormControlStyles>
                    <InputLabel> Select Utility </InputLabel>
                    <SelectStyles
                      value={initialFormValues.utility}
                      onChange={(e) => handleDropChange2(e)}
                      name={"utility"}
                    >
                      <MenuItem value={"CentralHudson"}>
                        Central Hudson
                      </MenuItem>

                      <MenuItem value={"ConEdison"}>Con Edison</MenuItem>
                      <MenuItem value={"NationalGrid"}>National Grid</MenuItem>
                      <MenuItem value={"NYSEG"}>NYSEG</MenuItem>
                      <MenuItem value={"Orange&Rockland"}>
                        {`Orange & Rockland`}
                      </MenuItem>

                      <MenuItem value={"RG&E"}>{`RG&E`}</MenuItem>
                      <MenuItem value={"Freeport"}>Freeport</MenuItem>
                      <MenuItem value={"PSEGLI"}>PSEGLI</MenuItem>
                    </SelectStyles>
                  </FormControlStyles>
                )}
                <FormControlStyles>
                  <InputLabel> Panel </InputLabel>
                  <SelectStyles
                    value={otherValues.solarPanel}
                    onChange={(e) => handleDropChange(e)}
                    name={"solarPanel"}
                  >
                    {allPanels.map((product) => (
                      <MenuItem key={product.name} value={product.name}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </SelectStyles>
                </FormControlStyles>
                <FormControlStyles>
                  <InputLabel> Inverter </InputLabel>
                  <SelectStyles
                    value={otherValues.inverter}
                    onChange={(e) => handleDropChange(e)}
                    name={"inverter"}
                  >
                    {inverters
                      .filter(
                        (x) =>
                          x.name === "Enphase IQ7-60-2-US" ||
                          x.name === "Enphase IQ7-60-2-US-208" ||
                          x.name === "Enphase IQ7PLUS-72-2-US" ||
                          x.name === "Enphase IQ7PLUS-72-2-US-208" ||
                          x.name === "Enphase IQ8PLUS-72-2-US" ||
                          x.name === "Enphase IQ8-60-2-US"
                      )
                      .map((inverter) => (
                        <MenuItem key={inverter.name} value={inverter.name}>
                          {inverter.name}
                        </MenuItem>
                      ))}
                  </SelectStyles>
                </FormControlStyles>
              </FormItself>
            ) : (
              ProposalFormToReturn()
            )}

            <SubmissionDiv>
              <p style={{ color: "#f44336" }}>{message}</p>
              <br />
              {!next ? (
                <UserCallToAction>
                  <button onClick={(e) => checkNextStep(e)}>Continue</button>
                </UserCallToAction>
              ) : (
                <ButtonCon>
                  <UserCallToAction>
                    <button
                      disabled={modalState}
                      onClick={(e) => onClickBack(e)}
                    >
                      Back
                    </button>
                  </UserCallToAction>
                  <UserCallToAction>
                    <button
                      disabled={modalState}
                      onClick={(e) => onSubmitForm(e)}
                      type="submit"
                    >
                      Submit{modalState && "ting"}
                    </button>
                  </UserCallToAction>
                </ButtonCon>
              )}
            </SubmissionDiv>
          </form>
        </StyledFormCon>
      </StyledCon>
    </>
  );
};

export default StartProposalV2;
