import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/react-hooks";
import ProposalTable from "../../components/ProposalTable/ProposalTable";
import { COMPLETED_PROPOSALS_QUERY } from "../../utils/GQL/QLQueries";

import OtherLoader from "../../components/OtherLoader/OtherLoader";

import { Public } from "@material-ui/icons";
import DispatchProposalStart from "../../components/DIspatchProposalStart/DispatchProposalStart";
import { Context } from "../../Context/ProposalContext";

const CompletedCon = styled.div`
  display: flex;
  flex-direction: "row";
`;
export const tableHeaders = [
  { id: "1", label: "Sales Rep", key: "RepName" },
  { id: "2", label: "Opportunity Number", key: "oppnum" },
  { id: "3", label: "Customer", key: "CustomerName" },
  { id: "4", label: "Office", key: "State" },
  { id: "5", label: "Appointment Address", key: "Address" },
  { id: "6", label: "Preparer", key: "preparerEmail" },
  {
    id: "7",
    label: "Completed",
    key: "completedOn",
    sortBy: "completedOnUnix",
  },
  { id: "7.1", label: "Type", key: "proposalType" },
  { id: "8", label: "Dispatch Revision", key: "revision", sortless: true },
  { id: "9", label: "HOA Revision", key: "hoarevision", sortless: true },
];
export const adminTableHeaders = [
  { id: "1", label: "Sales Rep", key: "RepName" },
  { id: "2", label: "Opportunity Number", key: "oppnum" },
  { id: "3", label: "Customer", key: "CustomerName" },
  { id: "4", label: "Office", key: "State" },
  { id: "5", label: "Appointment Address", key: "Address" },
  { id: "6", label: "Preparer", key: "preparerEmail" },
  { id: "7", label: "Completed", key: "completedOn" },
  { id: "7.1", label: "Type", key: "proposalType" },
  { id: "7.5", label: "Digital Proposal", key: "navButton", sortless: true },
  { id: "8", label: "Dispatch Revision", key: "revision", sortless: true },
  { id: "9", label: "HOA Revision", key: "hoarevision", sortless: true },
];

const imagesOnlyTableHeaders = [
  { id: "1", label: "Sales Rep", key: "RepName" },
  { id: "2", label: "Opportunity Number", key: "oppnum" },
  { id: "3", label: "Customer", key: "CustomerName" },
  { id: "4", label: "Office", key: "State" },
  { id: "5", label: "Appointment Address", key: "Address" },
  { id: "6", label: "Preparer", key: "preparerEmail" },

  { id: "7.1", label: "Type", key: "proposalType" },
  { id: "8", label: "Design Image", key: "designUrl", sortless: true },
];

const Completed = ({ data }) => {
  const { appPermissions } = useContext(Context);
  const { permissionsList } = appPermissions;

  const { admin, user } = appPermissions;

  if (permissionsList.includes("HOAONLY"))
    return <Admin tableHeaders={tableHeaders} />;
  if (permissionsList.includes("IMAGESONLY"))
    return <Admin tableHeaders={imagesOnlyTableHeaders} />;
  if (admin) return <Admin tableHeaders={adminTableHeaders} />;
  if (user) return <Admin tableHeaders={adminTableHeaders} />;
  // if (user) return <User data={data} tableHeaders={tableHeaders} />;
};
// eslint-disable-next-line
const User = ({ data, tableHeaders }) => {
  const newData = data.proposalsCompleted.reduce((arr, curr) => {
    arr.push({
      ...curr,

      revision: <DispatchProposalStart data={curr} revision={false} />,
      hoarevision: <DispatchProposalStart data={curr} revision={true} />,
    });
    return arr;
  }, []);

  tableHeaders.splice(5, 1);
  return (
    <CompletedCon>
      <ProposalTable tableHeaders={tableHeaders} data={newData} />
    </CompletedCon>
  );
};

const Admin = ({ tableHeaders }) => {
  const [getData, { data, loading, error, called, fetchMore }] = useLazyQuery(
    COMPLETED_PROPOSALS_QUERY
  );

  useEffect(() => {
    getData({
      variables: {
        limit: 1000,
      },
    });
  }, [getData]);

  if (loading || !called) return <OtherLoader />;
  if (error) return <div>An Error Occurred!</div>;
  // const newTableHeaders = [
  //   ...tableHeaders,
  //   { id: "10", label: "jsafd", key: "navButton" }
  // ];

  const newData = data.allCompletedProposalsPaginated.reduce((arr, curr) => {
    arr.push({
      ...curr,
      navButton: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://proposal.momentumsolar.app/home/${curr.id}`}
        >
          <Public />
        </a>
      ),
      designUrl: (
        <a target="_blank" rel="noopener noreferrer" href={`${curr.designUrl}`}>
          <Public />
        </a>
      ),

      revision: <DispatchProposalStart data={curr} revision={false} />,
      hoarevision: <DispatchProposalStart data={curr} revision={true} />,
    });
    return arr;
  }, []);

  return (
    <CompletedCon>
      <ProposalTable
        tableHeaders={tableHeaders}
        data={newData}
        fetchMore={fetchMore}
      />
    </CompletedCon>
  );
};

export default Completed;
