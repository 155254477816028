import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { NonUniquesContainer } from "../../Refactored/StartProposalV2/styles";
import styled from "styled-components";
import { Context } from "../../Context/ProposalContext";
import { client } from "../../App";
import { ALL_PRODUCTS_QUERY } from "../../utils/ProductsQueries";
import { BatteryContext } from "../../Context/BatteryContext";

import {
  adjustedEPC,
  getEPC,
  getSystemSize,
  getSolarITC,
  getUtilityEscalator,
  getLoanAmount,
  getSolarPayment,
  getMonthlySolarBill,
  getMonthlyBill,
  solarPlusUtil,
} from "../../Refactored/StartProposalV2/Calculations";
import {
  Cashes,
  Loans,
  IGSES,
} from "../../Refactored/StartProposalV2/StartProposalV2";

const StyledRow = styled(TableRow)`
  background: rgb(0, 174, 239, 0.4);
  && {
    color: white;
  }

  .cell-styles {
    color: white;
    font-weight: 700;
  }
`;

const NonUniqueStatic = () => {
  const {
    otherValues,
    initialFormValues,
    austinEnergy,
    monthValues,
    consumptionType,
  } = useContext(Context);
  const { generacForm, setGeneracForm } = useContext(BatteryContext);

  const {
    allProducts: { allPanels },
  } = client.readQuery({
    query: ALL_PRODUCTS_QUERY,
  });

  const { proposalType } = otherValues;

  return (
    <NonUniquesContainer>
      <Table>
        <TableHead>
          <StyledRow>
            <TableCell className="cell-styles">Utility Escalator</TableCell>
            <TableCell className="cell-styles">Size</TableCell>

            {!IGSES.includes(proposalType) && <CashAndLoanRow />}
            {!Cashes.includes(proposalType) && <LoanAndIGSRow />}

            {Loans.includes(proposalType) && <JustLoanRow />}

            {IGSES.includes(proposalType) && (
              <TableCell className="cell-styles"> Solar Bill</TableCell>
            )}
          </StyledRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {getUtilityEscalator(initialFormValues.State)}%
            </TableCell>
            <TableCell>{getSystemSize(otherValues, allPanels)}</TableCell>

            {!IGSES.includes(proposalType) && (
              <CashAndLoanCells otherValues={otherValues} />
            )}

            {!Cashes.includes(proposalType) && (
              <LoanAndIGSCells
                otherValues={otherValues}
                initialFormValues={initialFormValues}
                austinEnergy={austinEnergy}
                monthValues={monthValues}
                consumptionType={consumptionType}
              />
            )}
            {Loans.includes(proposalType) && (
              <JustLoanCells
                otherValues={otherValues}
                initialFormValues={initialFormValues}
                austinEnergy={austinEnergy}
              />
            )}

            {IGSES.includes(proposalType) && (
              <TableCell> {getMonthlySolarBill(otherValues)}</TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
      {(proposalType === "GeneracLoan" || proposalType === "JEALoan") && (
        <Table>
          <TableHead>
            <StyledRow>
              <TableCell className="cell-styles">Battery Size</TableCell>
              <TableCell className="cell-styles">Battery EPC</TableCell>
              <TableCell className="cell-styles">
                Battery Adjusted EPC
              </TableCell>
              <TableCell className="cell-styles">Battery ITC</TableCell>
              <TableCell className="cell-styles">Battery Loan Amount</TableCell>
              <TableCell className="cell-styles">
                Battery Loan Payment
              </TableCell>
            </StyledRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {/* battery size = 3.36 * battery module */}
              <TableCell>
                {(3.36 * +generacForm.batteryModuleQty).toFixed(2)}
              </TableCell>
              {/* battery epc = batterySize * batteryPPW * 1000 */}
              <TableCell>
                {(
                  3.36 *
                  +generacForm.batteryModuleQty *
                  +generacForm.batteryPPW *
                  1000
                ).toFixed(2)}
              </TableCell>
              {/* battery adjusted EPC = battery epc - battery rebate - enphase rebate */}
              <TableCell>
                {(
                  3.36 *
                    +generacForm.batteryModuleQty *
                    +generacForm.batteryPPW *
                    1000 -
                  +generacForm.enphaseRebate -
                  +generacForm.batteryRebate
                ).toFixed(2)}
              </TableCell>
              {/* battery itc = battery adjusted epc * 0.26 */}
              <TableCell>
                {(
                  (3.36 *
                    +generacForm.batteryModuleQty *
                    +generacForm.batteryPPW *
                    1000 -
                    +generacForm.enphaseRebate -
                    +generacForm.batteryRebate) *
                  0.26
                ).toFixed(2)}
              </TableCell>
              {/* battery loan amount = battery epc - battery rebate */}
              <TableCell>
                {(
                  3.36 *
                  +generacForm.batteryModuleQty *
                  +generacForm.batteryPPW *
                  1000
                ).toFixed(2)}
              </TableCell>
              {/* battery loan payment = battery loan amount x payment factor */}
              <TableCell>
                {" "}
                {(
                  3.36 *
                  +generacForm.batteryModuleQty *
                  +generacForm.batteryPPW *
                  1000 *
                  +otherValues.paymentFactor
                ).toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </NonUniquesContainer>
  );
};

const LoanAndIGSRow = () => {
  return (
    <>
      <TableCell className="cell-styles"> Utility Bill</TableCell>

      <TableCell className="cell-styles"> Solar & Utility Bill</TableCell>
    </>
  );
};

const LoanAndIGSCells = ({
  otherValues,
  monthValues,
  initialFormValues,
  austinEnergy,
  consumptionType,
}) => {
  return (
    <>
      <TableCell>
        {getMonthlyBill(otherValues, monthValues, consumptionType)}
      </TableCell>

      <TableCell>
        {solarPlusUtil(
          otherValues,
          initialFormValues,
          austinEnergy,
          monthValues
        ).toFixed(2)}
      </TableCell>
    </>
  );
};

const JustLoanRow = () => {
  return (
    <>
      <TableCell className="cell-styles"> Loan Amount</TableCell>
      <TableCell className="cell-styles"> Loan Payment</TableCell>
    </>
  );
};

const JustLoanCells = ({ otherValues, initialFormValues, austinEnergy }) => {
  return (
    <>
      <TableCell>
        {getLoanAmount(otherValues, initialFormValues, austinEnergy).toFixed(2)}
      </TableCell>
      <TableCell>
        {getSolarPayment(otherValues, initialFormValues, austinEnergy).toFixed(
          2
        )}
      </TableCell>
    </>
  );
};

const CashAndLoanRow = () => {
  return (
    <>
      <TableCell className="cell-styles">EPC</TableCell>
      <TableCell className="cell-styles">Adjusted EPC</TableCell>
      <TableCell className="cell-styles">Solar ITC</TableCell>
    </>
  );
};

const CashAndLoanCells = ({ otherValues }) => {
  return (
    <>
      <TableCell> {getEPC(otherValues).toFixed(2)} </TableCell>
      <TableCell> {adjustedEPC(otherValues).toFixed(2)}</TableCell>
      <TableCell> {getSolarITC(otherValues).toFixed(2)}</TableCell>
    </>
  );
};

export default NonUniqueStatic;
