import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import {
  Paper,
  Table,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  ButtonGroup,
} from "@material-ui/core";
import {
  ArrowUpward,
  ArrowDownward,
  UnfoldMore,
  FileCopy,
} from "@material-ui/icons";
import {
  tableFilter,
  inHomeOutOfHomeCounter,
} from "../../Refactored/StartProposalV2/Helpers";
import CustomPagination from "../CustomPagination/CustomPagination";
import SearchDropdown from "./SearchDropdown";
import StateFilter from "./StateFilter";
import Count from "../Count/Count";
import { Context } from "../../Context/ProposalContext";
export const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  height: 100%;
  .root {
    margin: auto;
  }
  .tableWrapper {
    height: ${(props) => (props.height ? props.height : "100%")};
    overflow: auto;
  }
  .editBtn {
    width: 50px;
    text-align: center;
  }
  .searchBar {
    margin: 15px 0;
    min-width: 350px;
  }
  .searchBarWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .primaryBtn {
    color: #0016;
  }
  .headerSort {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  p.email {
    margin: 0;
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  a {
    text-decoration: none;
    color: black;
  }

  .file-copy {
    margin-left: 5px;
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme[props.isselected.color]};
  font-weight: ${(props) => props.isselected.fontWeight};
  font-size: ${(props) => props.isselected.fontSize};
`;

const ProposalTable = ({
  data,
  tableHeaders,
  history,

  fetchMore,
  HidePaginationProp,
  disableHomeFilter,
  rowsPerPageProp,
}) => {
  // state objects
  const { currentFilter, setCurrentFilter } = useContext(Context);
  //const [latestId, setLatestId] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    rowsPerPageProp ? rowsPerPageProp : 10
  );
  const [currentOffices, setCurrentOffices] = useState([
    { AState: "PA", State: "PA", set: true, key: "PA" },
    { AState: "NY", State: "PNY", set: true, key: "Plainview NY" },
    { AState: "FL", State: "TFL", set: true, key: "Tampa FL" },
    { AState: "FL", State: "FTL", set: true, key: "Ft. Lauderdale FL" },
    { AState: "TX", State: "SATX", set: true, key: "San Antonio TX" },
    { AState: "TX", State: "DAL", set: true, key: "Dallas TX" },
    { AState: "FL", State: "OFL", set: true, key: "Orlando FL" },
    { AState: "NJ", State: "MTNJ", set: true, key: "Metuchen NJ" },
    { AState: "CT", State: "STCT", set: true, key: "Stamford CT" },
    { AState: "CA", State: "OCA", set: true, key: "Orange CA" },
    { AState: "TX", State: "AUTX", set: true, key: "Austin TX" },
    { AState: "NJ", State: "CHNJ", set: true, key: "Cherry Hill NJ" },
  ]);
  // const [currentOffices, setCurrentOffices] = useState([
  //   {State: 'PA', set: true, key: 'PA'},
  //   PA: true,
  //   "Plainview NY": true,
  //   "Tampa FL": true,
  //   "Ft. Lauderdale FL": true,
  //   "San Antonio TX": true,
  //   "Dallas TX": true,
  //   "Orlando FL": true,
  //   "Metuchen NJ": true,
  //   "Stamford CT": true,
  //   "Orange CA": true,
  //   "Austin TX": true,
  //   "Cherry Hill NJ": true
  // ]);
  const [page, setPage] = React.useState(0);
  const [currSort, setCurrSort] = useState({ key: "", direction: "" });
  const [currSearch, setCurrSearch] = useState("oppnum");
  const [search, setSearch] = useState("");

  // utility functions
  const sortHandler = (key, sortBy) => {
    let sortedBy = sortBy ? sortBy : key;

    if (!key) {
      return;
    } else if (!currSort.key) {
      setCurrSort({
        key,
        sortBy: sortedBy,
        direction: 1,
      });
    } else if (currSort.key === key || currSort.sortBy === sortedBy) {
      let direction = !currSort.direction
        ? 1
        : currSort.direction === 1
        ? 2
        : "";
      setCurrSort({ ...currSort, direction });
    } else {
      setCurrSort({
        key,
        sortBy: sortedBy,
        direction: 1,
      });
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    // Also want to fetch on second to last page, it current;y is not 7/16
    if (fetchMore) {
      const lastId = data[data.length - 1].id;

      const totalPages = tableData.length / rowsPerPage;

      // if (lastId === latestId) {
      //   console.log("Ids Same Return ");
      //   return;
      // }

      // setLatestId(lastId);
      if (totalPages <= newPage + 1) {
        fetchMore({
          variables: {
            cursor: lastId,
            limit: 500,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            return Object.assign({}, prev, {
              allCompletedProposalsPaginated: [
                ...prev.allCompletedProposalsPaginated,
                ...fetchMoreResult.allCompletedProposalsPaginated,
              ],
            });
          },
        });
      }
      return;
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const buttonColor = (name) => {
    if (currentFilter === name) {
      return { color: "midnightBlue", fontWeight: "bolder", fontSize: "18px" };
    }
    return { color: "cadetBlue", fontWeight: "normal", fontSize: "14px" };
  };

  // handle sort arrow ui
  const currArrow = (key, sortBy) => {
    if (key !== currSort.key || !currSort.direction) return <UnfoldMore />;
    if (key === currSort.key && currSort.direction === 1)
      return <ArrowDownward />;
    if (key === currSort.key && currSort.direction === 2)
      return <ArrowUpward />;
  };

  //loading state indicator
  // if (loading) return <OtherLoader />;
  // // ! error indicator need to handle error
  // if (error) return <div>An Error Occurred!</div>;

  const tableData = tableFilter(
    data,
    search,
    currentFilter,
    currSort,
    currSearch,
    currentOffices,
    disableHomeFilter
  );

  return (
    <Wrapper>
      <div className="searchBarWrapper">
        {disableHomeFilter ? null : (
          <ButtonGroup
            aria-label="outlined primary button group"
            variant="text"
          >
            <StyledButton
              onClick={() => setCurrentFilter("inhome")}
              // eslint-disable-next-line no-use-before-define
              isselected={buttonColor("inhome")}
            >
              In Home
              <Count count={inHomeOutOfHomeCounter(data, "inhome")} />
            </StyledButton>
            <StyledButton
              onClick={() => setCurrentFilter("outhome")}
              // eslint-disable-next-line no-use-before-define
              isselected={buttonColor("outhome")}
            >
              Out of Home
              <Count count={inHomeOutOfHomeCounter(data, "outhome")} />
            </StyledButton>
          </ButtonGroup>
        )}

        {history.location.pathname === "/" && (
          <StateFilter states={currentOffices} setStates={setCurrentOffices} />
        )}
        <SearchDropdown
          currSearch={currSearch}
          setCurrSearch={setCurrSearch}
          search={search}
          setSearch={setSearch}
          relatedData={tableHeaders}
        />
      </div>
      <div className="searchBarWrapper">
        {history.location.pathname === "/completed" && (
          <>
            <StateFilter
              states={currentOffices}
              setStates={setCurrentOffices}
            />
          </>
        )}
      </div>

      <Paper className="root">
        <div className="tableWrapper">
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((column) => (
                  <TableCell
                    onClick={() => sortHandler(column.key, column.sortBy)}
                    key={column.mapkey ? column.mapkey : column.id}
                  >
                    <div className="headerSort">
                      {column.label}
                      {currArrow(column.key, column.sortBy)}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      // style={row.reprequest && { background: "yellow" }}
                      key={`${row.id ? row.id : row._id}-${row.oppnum}`}
                    >
                      {tableHeaders.map((col) => {
                        if (col.key === "none") return null;
                        if (col.key === "RepName") {
                          return (
                            <TableCell key={col.key}>
                              <p className="email">
                                <a href={`mailto:${row.RepEmail}`}>
                                  {row.RepName}
                                </a>
                              </p>
                            </TableCell>
                          );
                        }
                        if (col.key === "AppointmentDateString") {
                          return (
                            <TableCell key={col.key}>
                              {moment(row[col.key]).format("MM-DD-YYYY")}
                            </TableCell>
                          );
                        }
                        if (col.key === "completedOn") {
                          return (
                            <TableCell key={col.key}>
                              <div className="cell-row">
                                {row.completedOn}
                                {row.pdfUrl && (
                                  <a
                                    href={row.pdfUrl}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    download
                                  >
                                    <FileCopy
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          row.pdfUrl
                                        )
                                      }
                                      className="file-copy"
                                    />
                                  </a>
                                )}
                              </div>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={`${row.id}--${col.key}`}>
                              {row[col.key] || `N/A`}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {HidePaginationProp ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            ActionsComponent={CustomPagination}
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Wrapper>
  );
};

export default withRouter(ProposalTable);
