import React, { createContext, useState } from "react";
export const Context = createContext({});

const v2FormValuesLocal = {
  State: "",
  Address: "",
  City: "",
  Zip: "",
  RepName: "",
  CustomerName: "",
  RepEmail: "",
  oppnum: "",
  FinancerId: "",
  msesPrime: "",
  proposalReason: "",
  proposalType: "",
  inverterWattage: "",
  panelWattage: "",
  solarPanel: "",
  panels: "",
  inverter: "",
  finnancialPartner: "",
  production: "",
  ppW: "",
  // srecs: "",
  consumption: "",
  utilityRate: "",
  solarRate: "",
  downPayment: "",
  interest: "",
  // Not a user input -- Functionally return get rid of
  // utilityEscalator: "",
  solarEscalator: "",
  loanTerm: "",
  rebate: "",
  // File itself, suggest moving to it's own piece of state
  designImageUpload: "",
  designUrl: "",
  panelInfo: "",
  additionalwork: [
    {
      type: "tree",
      notes: "",
      img: "",
      filePreview: "",
      required: "",
      estimatedCost: "",
    },
    {
      type: "trench",
      notes: "",
      img: "",
      filePreview: "",
      required: "",
      estimatedCost: "",
    },
  ],
};

export const initialFormVals = {
  State: "",
  Address: "",
  City: "",
  Zip: "",
  RepName: "",
  CustomerName: "",
  RepEmail: "",
  oppnum: "",
  FinancerId: "",
};

export const initialAdditionalWork = [
  {
    type: "tree",
    notes: "",
    img: "",
    filePreview: "",
    required: "",
    estimatedCost: "",
  },
  {
    type: "trench",
    notes: "",
    img: "",
    filePreview: "",
    required: "",
    estimatedCost: "",
  },
];

// Not a user input -- We should make getting these values more functional
// DO not chnage this value on dropdown select but rather write a function to get these
// Values based on the panel or inverter name passed in. Functions like EPC also rely on this value
// interest, utilityEscalator, invertWattage, & panelWattage are all not user inputs
export const initialOtherValues = {
  msesPrime: "",
  proposalReason: "",
  proposalSubreason: "",
  proposalType: "",
  inverterWattage: "",
  panelWattage: "",
  solarPanel: "",
  panels: "",
  inverter: "",
  finnancialPartner: "",
  production: "",
  ppW: "",
  // srecs: "",
  consumption: "",
  utilityRate: "",
  solarRate: "",
  downPayment: "",
  interest: "",
  // Not a user input -- Functionally return get rid of
  // utilityEscalator: "",
  solarEscalator: "",
  loanTerm: "",
  rebate: "",
  // File itself, suggest moving to it's own piece of state
  designImageUpload: "",
  designUrl: "",
  panelInfo: "",
  additionalwork: [...initialAdditionalWork],
};

export const allFormValues = [
  ...Object.keys(initialFormVals),
  ...Object.keys(initialOtherValues),
];

const ProposalContextProvider = ({ children }) => {
  const [v2FormValues, setV2FormValues] = useState(v2FormValuesLocal);
  const [initialProposalData, setInitialProposalData] = useState();
  const [users, setUsers] = useState();
  const [utilityList, setUtilityList] = useState([]);
  const [utilitySubmit, setUtilitySubmit] = useState({});
  const [demoid, setDemoId] = useState("");
  const [userValues, setValue] = useState({
    name: "",
    userName: "",
  });
  const [monthValues, setMonthVals] = useState([
    { month: "Jan", days: 31, consumption: "", totalCost: 0 },
    { month: "Feb", days: 28, consumption: "", totalCost: 0 },
    { month: "March", days: 31, consumption: "", totalCost: 0 },
    { month: "April", days: 30, consumption: "", totalCost: 0 },
    { month: "May", days: 31, consumption: "", totalCost: 0 },
    { month: "June", days: 30, consumption: "", totalCost: 0 },
    { month: "July", days: 31, consumption: "", totalCost: 0 },
    { month: "August", days: 31, consumption: "", totalCost: 0 },
    { month: "Sept", days: 30, consumption: "", totalCost: 0 },
    { month: "Oct", days: 31, consumption: "", totalCost: 0 },
    { month: "Nov", days: 30, consumption: "", totalCost: 0 },
    { month: "Dec", days: 31, consumption: "", totalCost: 0 },
  ]);
  const [show, setShow] = useState(true);
  const [allProducts, setAllProducts] = useState();
  const [revision, setRevision] = useState(false);
  const [activeTab, setActiveTab] = useState("Assign");
  const [currentFilter, setCurrentFilter] = useState("");
  const [fID, setFID] = useState("");
  const [initialFormValues, setInitialValues] = useState(initialFormVals);
  const [consumptionType, setConsumptionType] = useState("Annual");
  const [otherValues, setOtherValues] = useState(initialOtherValues);
  const [austinEnergy, setAustinEnergy] = useState({
    yes: false,
    no: false,
  });
  const [additionalWorkRequired, setAdditionalWorkRequired] = useState(false);
  // const [startEndDates, setStartEndDates] = useState({
  //   endDate: moment().subtract(7, "days"),
  //   startDate: moment(),
  // });

  const [sessionActivated, setSessionActivated] = useState(false);
  const [appPermissions, setPermissions] = useState({
    permissionsList: [],
    admin: "",
    user: "",
    _id: "",
  });
  const [proposalPreview, setProposalPreview] = useState({
    pdfUrl: "",
    digitalUrl: "",
    userId: "",
    proposalId: "",

    completedProposalId: "",
  });
  const [useOriginal, setUseOriginal] = useState(true);
  return (
    <Context.Provider
      value={{
        v2FormValues,
        setV2FormValues,
        additionalWorkRequired,
        setAdditionalWorkRequired,
        userValues,
        setValue,
        austinEnergy,
        setAustinEnergy,
        revision,
        setRevision,
        //setProposalData,
        //proposalData,
        setUsers,
        users,
        initialProposalData,
        setInitialProposalData,
        monthValues,
        setMonthVals,
        initialFormValues,
        setInitialValues,
        otherValues,
        setOtherValues,
        show,
        setShow,
        fID,
        setFID,
        allProducts,
        setAllProducts,
        sessionActivated,
        setSessionActivated,
        appPermissions,
        setPermissions,
        activeTab,
        setActiveTab,
        currentFilter,
        setCurrentFilter,
        proposalPreview,
        setProposalPreview,
        useOriginal,
        setUseOriginal,
        consumptionType,
        setConsumptionType,
        demoid,
        setDemoId,
        utilityList,
        setUtilityList,
        utilitySubmit,
        setUtilitySubmit,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ProposalContextProvider;
