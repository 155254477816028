//import moment from "moment";

const onSetRotation = (rotation, setRotation) => {
  if (rotation === 180) {
    setRotation(0);
  }

  if (rotation === 0) {
    setRotation(180);
  }
};

const handleSRECIncome = (production, State) => {
  let kwHours = production / 1000;
  let initialkwHours = kwHours;
  let remainder = 0;
  const SRECValue = State === "NJ" ? 200 : 40;
  let arr = [];
  for (let i = 0; i < 10; i++) {
    initialkwHours = i === 0 ? initialkwHours : initialkwHours * 0.995;
    //remainder = initialkwHours % 1 + remainder;
    let newRemainder = (initialkwHours % 1) + remainder;

    let whole = Math.floor(kwHours) + Math.floor(newRemainder);

    if (Math.floor(newRemainder) > 0) {
      remainder = remainder - 1;
    }

    let currSrec = whole * SRECValue;
    arr.push(currSrec);
  }
  return arr;
};

//30%
const SunlightArr = [
  { term: 15, interest: 3.99, paymentFactor: 0.0053433 },
  { term: 20, interest: 5.99, paymentFactor: 0.0052327 },
  { term: 25, interest: 4.49, paymentFactor: 0.0040183 },
];

//old dividend
// const DividendArr = [
//   { term: 12, interest: 2.99, paymentFactor: 0.0059372 },
//   { term: 12, interest: 3.99, paymentFactor: 0.0063162 },
//   { term: 12, interest: 4.99, paymentFactor: 0.0067108 },
//   { term: 20, interest: 2.99, paymentFactor: 0.0039656 },
//   { term: 20, interest: 5.99, paymentFactor: 0.0052126 },
//   { term: 25, interest: 2.99, paymentFactor: 0.0033874 },
// ];

//30%
const DividendArr = [
  { term: 12, interest: 4.99, paymentFactor: 0.0067108 },
  { term: 20, interest: 4.49, paymentFactor: 0.0045636 },
  { term: 20, interest: 2.99, paymentFactor: 0.0039656 },
  { term: 25, interest: 3.99, paymentFactor: 0.0037928 },
  { term: 25, interest: 2.99, paymentFactor: 0.0033874 },
];
const DividendKeyBank = [
  { term: 12, interest: 4.99, paymentFactor: 0.0067108 },
  { term: 20, interest: 4.49, paymentFactor: 0.0045636 },
  { term: 20, interest: 2.99, paymentFactor: 0.0039656 },
  { term: 25, interest: 3.99, paymentFactor: 0.0037928 },
  { term: 25, interest: 2.99, paymentFactor: 0.0033874 },
];

const setTheState = (e, toUse, values, setValues) => {
  setValues({
    ...values,
    [e.target.name]: toUse.term,
    interest: toUse.interest,
    paymentFactor: toUse.paymentFactor,
  });
};

const handleDropChange = (e, values, setValues, isKeyBank) => {
  if (values.finnancialPartner === "GreenSky") {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      interest: 5.99,
      paymentFactor: 0.005616,
    });
  }

  if (values.finnancialPartner === "Sunlight") {
    const toUse = SunlightArr.find(
      (sunlight) => sunlight.term === e.target.value
    );
    setTheState(e, toUse, values, setValues);
  }

  if (values.finnancialPartner === "Dividend" && isKeyBank) {
    const toUse = DividendKeyBank.find((div) => div.term === e.target.value);

    setTheState(e, toUse, values, setValues);
  }

  if (values.finnancialPartner === "Dividend" && !isKeyBank) {
    const toUse = DividendArr.find((div) => div.term === e.target.value);

    setTheState(e, toUse, values, setValues);
  }
};

export const handleEmpowerChange = (values, setValues, isKeyBank) => {
  const setStateByEmpower = (values, setValues, toUse) => {
    setValues({
      ...values,

      interest: toUse.interest,
      paymentFactor: toUse.paymentFactor,
    });
  };

  if (isKeyBank) {
    const toUse = DividendKeyBank.find((div) => div.term === values.loanTerm);

    setStateByEmpower(values, setValues, toUse);
    return;
  }

  if (!isKeyBank) {
    const toUse = DividendArr.find((div) => div.term === values.loanTerm);

    setStateByEmpower(values, setValues, toUse);
    return;
  }
};

const capEachFirstLetter = (str) => {
  if (str === null) return;
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};
const inHomeFilter = (data, currentFilter, disableHomeFilter) => {
  if (disableHomeFilter) return data;
  switch (currentFilter) {
    case "inhome":
      return data.filter((propdata) => propdata.inhome);

    case "outhome":
      return data.filter((propdata) => !propdata.inhome);

    default:
      return data;
  }
  // ref old.js for org date filters
};

const inHomeOutOfHomeCounter = (data, currentFilter) => {
  switch (currentFilter) {
    case "inhome":
      return data.filter((propdata) => propdata.inhome).length;

    case "outhome":
      return data.filter((propdata) => !propdata.inhome).length;

    default:
      return -1;
  }
};

const sortFilter = (data, currSort) => {
  if (currSort.key && currSort.direction) {
    let sortByKey = currSort.sortBy ? currSort.sortBy : currSort.key;
    let directionOne = currSort.direction === 1 ? 1 : -1;
    let directionTwo = currSort.direction === 1 ? -1 : 1;
    if (currSort.key === "AppointmentDateString") {
      data.sort((a, b) =>
        !currSort.direction || currSort.direction === 1
          ? new Date(`${b.AppointmentDateString} ${b.AppointmentTimeString}`) -
            new Date(`${a.AppointmentDateString} ${a.AppointmentTimeString}`)
          : new Date(`${a.AppointmentDateString} ${a.AppointmentTimeString}`) -
            new Date(`${b.AppointmentDateString} ${b.AppointmentTimeString}`)
      );
    } else {
      data.sort((a, b) =>
        a[sortByKey] < b[sortByKey] ? directionOne : directionTwo
      );
    }
  } else {
    data.sort(
      (a, b) =>
        new Date(`${b.AppointmentDateString} ${b.AppointmentTimeString}`) -
        new Date(`${a.AppointmentDateString} ${a.AppointmentTimeString}`)
    );
  }
  return data;
};

const searchFilter = (data, textInput, key) => {
  if (textInput) {
    const searchRes = data.filter(
      (val) =>
        val[key]
          .toString()
          .toLowerCase()
          .indexOf(textInput.toLowerCase().trim()) !== -1
    );

    if (searchRes.length > 0) {
      return searchRes;
    } else return data;
  } else {
    return data;
  }
};

const filterByOffice = (data, currentOffices) => {
  if (!currentOffices) return data;
  const unique = [...new Set(...data.map((x) => Object.keys(x)))];

  let allTrue = currentOffices.filter((x) => x.set);
  let filterThese;

  if (allTrue.length === 12) return data;

  if (!allTrue.length) return data;
  else {
    if (unique.includes("Office")) {
      filterThese = allTrue.map((x) => x.key);

      const filteredByOffice = data.reduce((prev, curr) => {
        if (filterThese.includes(curr.Office)) {
          prev.push(curr);
        }
        return prev;
      }, []);

      return filteredByOffice;
    } else {
      filterThese = allTrue.map((x) => x.AState);

      const filteredByOffice = data.reduce((prev, curr) => {
        if (filterThese.includes(curr.State)) {
          prev.push(curr);
        }
        return prev;
      }, []);

      return filteredByOffice;
    }
  }
};

const tableFilter = (
  data,
  textInput,
  currentFilter,
  currSort,
  currSearch,
  currentOffices,
  disableHomeFilter
) => {
  let filteredOffices = filterByOffice(data, currentOffices);
  let sortedByDate = inHomeFilter(
    filteredOffices,
    currentFilter,
    disableHomeFilter
  );
  let sortedData = sortFilter(sortedByDate, currSort);
  let searchData = searchFilter(sortedData, textInput, currSearch);
  return searchData;
};

const simpleTableFilter = (data, textInput, currSort, currSearch) => {
  let sortedData = sortFilter(data, currSort);
  let searchData = searchFilter(sortedData, textInput, currSearch);
  return searchData;
};

const annualELectricityCostM = (monthlyBill) => {
  let arr = [];
  for (let i = 0; i < 12; i++) {
    arr.push(Math.round(monthlyBill));
  }
  return arr;
};

const pullEmpties = (obj) =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

export const cleanNumbers = (data, areNumbersArray) =>
  Object.keys(data).reduce((prev, curr) => {
    prev = {
      ...prev,
      [curr]: areNumbersArray.includes(curr) ? +data[curr] : data[curr],
    };
    return prev;
  }, {});

export const deleteKeys = (data, deleteArray) =>
  Object.keys(data).reduce((prev, curr) => {
    if (deleteArray.includes(curr)) return prev;
    else {
      prev = { ...prev, [curr]: data[curr] };
    }

    return prev;
  }, {});

const cleanSubmissionData = (otherValues) => {
  //const areNumbers = ["ppW", "panels", "loanTerm", ""];
  console.log(otherValues);
  const pattern = /^\d+$/;

  let parseInts = Object.keys(otherValues).reduce((prev, curr) => {
    prev = {
      ...prev,
      [curr]: pattern.test(otherValues[curr])
        ? +otherValues[curr]
        : otherValues[curr],
    };
    return prev;
  }, {});
  let pulledEmpties = pullEmpties(parseInts);

  delete pulledEmpties.srecs;

  console.log(pulledEmpties);

  return pulledEmpties;
};

const whatSrec = (state) => {
  let srec = 0;
  if (state === "NJ") srec = 91.2;
  if (state === "PA") srec = 24;
  return srec;
};
// returns array of an objects keys
const keyExtractor = (obj) => Object.keys(obj);

const getProduct = (products, product, productType) => {
  const foundProduct = products.find(({ name }) => name === product);
  console.log(foundProduct);
  if (!foundProduct)
    return {
      brand: null,
      name: null,
      model: null,
      commonName: null,
      pmax: null,
      _id: null,
      productImage: null,
    };

  const {
    brand = null,
    name = null,
    model = null,
    commonName = null,
    pmax = null,
    _id = null,
    productImage = null,
  } = foundProduct;
  return {
    brand,
    name,
    model,
    commonName,
    pmax,
    _id,
    productImage,
  };
};

export {
  onSetRotation,
  handleDropChange,
  capEachFirstLetter,
  searchFilter,
  tableFilter,
  handleSRECIncome,
  annualELectricityCostM,
  inHomeOutOfHomeCounter,
  cleanSubmissionData,
  whatSrec,
  pullEmpties,
  getProduct,
  simpleTableFilter,
  keyExtractor,
};
