import React from "react";
import ProposalTable from "../../components/ProposalTable/ProposalTable";
import { tableHeaders } from "../Home";
const UsersProposals = ({ usersProposals }) => {
  let newTableHeaders = [
    ...tableHeaders,
    { id: "8", label: "Drop Proposal", key: "drop" },
  ];
  return <ProposalTable data={usersProposals} tableHeaders={newTableHeaders} />;
};

export default UsersProposals;
