import React, { useContext } from "react";
import { Create } from "@material-ui/icons";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { getProduct } from "../../Refactored/StartProposalV2/Helpers";
import UserCallToAction from "../UserCallToAction";
import { client } from "../../App";
import { ALL_PRODUCTS_QUERY } from "../../utils/ProductsQueries";
// eslint-disable-next-line
import { sendEmail2 } from "../../utils/API";
import { Context, allFormValues } from "../../Context/ProposalContext";

const CreateStyles = styled(Create)`
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const DispatchProposalStart = ({ data: proposalData, revision, history }) => {
  // console.log("???");
  const { allProducts } = client.readQuery({
    query: ALL_PRODUCTS_QUERY,
  });

  const {
    setAdditions,
    setInitialProposalData,
    setInitialValues,
    otherValues,
    setOtherValues,
    // eslint-disable-next-line
    userValues,
    setRevision,
  } = useContext(Context);

  let { allPanels, inverters } = allProducts;
  allPanels = allPanels.filter((panel) => panel.active);

  const dispatchTheStart = async () => {
    setRevision(revision);

    console.log(allFormValues);

    let keysToCopy = [
      "id",
      "pimg",
      "repNotes",
      "pvGenArray",
      "size",
      "production",
      "designUrl",
      "panels",
    ];
    proposalData.production = +proposalData.production;

    let initialPropData = Object.keys(proposalData).reduce((prev, k) => {
      if (keysToCopy.includes(k)) {
        console.log(prev, k);
        prev = {
          ...prev,
          [k]: proposalData[k],
        };
      }
      return prev;
    }, {});
    console.log("-----------MB------------");
    console.log("initial prop data");
    console.log(proposalData);
    console.log(initialPropData);
    console.log("-----------MB------------");

    setInitialProposalData(initialPropData);
    // const updateActionRes = await updateAction();

    // console.log(updateActionRes);

    // const proposalData = data.filter((proposal) => proposal.id === id);
    sendEmail2(proposalData, userValues);

    // Previous Prop Values

    let panel = getProduct(
      allPanels,
      proposalData.module || proposalData.solarPanel,
      "panel"
    );
    let inverter = getProduct(inverters, proposalData.inverter, "inverter");

    const { name: solarPanel = null, pmax: panelWattage = null } = panel;
    const { name: inverterName = null, pmax: inverterWattage = null } =
      inverter;

    console.log(solarPanel, inverterName, panelWattage, inverterWattage);

    // console.log(panel, inverter);
    const {
      Address = null,
      RepEmail = null,
      RepName = null,
      oppnum = null,
      Zip = null,
      City = null,
      CustomerName = null,
      production = null,
      panels = null,
      State = null,
      proposalType = null,
      utilityRate = null,
      solarEscalator = null,
      consumption = null,
      solarRate = null,
      ppW = null,
      designUrl = null,
      loanAmount = null,
      rebate = null,
      finnancialPartner = null,
      timerequested = null,
    } = proposalData;

    setOtherValues({
      ...otherValues,
      inverterWattage: inverterWattage,
      panelWattage,
      solarPanel,
      panels,
      inverter: inverterName,
      production,
      proposalType,
      size: (panel && panel.pmax * panels) / 1000,
      utilityRate,
      solarEscalator,
      consumption,
      panelImageUrl: panel && panel.productImage && panel.productImage.panelUrl,
      rebate,
      solarRate,
      panelInfo: panel && panel._id,
      ppW,
      designUrl,
      loanAmount,
      finnancialPartner,
      timerequested,
    });

    setInitialValues({
      State,
      City,
      Zip,
      Address,
      RepName,
      RepEmail,
      CustomerName,
      oppnum:
        userValues.userName === "CLantier@momentumsolar.com" ? "FAKE" : oppnum,
    });
    history.push(`/startpropsal/${proposalData.id}`);
  };
  return (
    <UserCallToAction>
      <CreateStyles onClick={() => dispatchTheStart()} />
    </UserCallToAction>
  );
};
export default withRouter(DispatchProposalStart);
