import React, { useContext } from "react";
import { BatteryContext } from "../../Context/BatteryContext";
import { TextFieldStyles } from "../../Refactored/StartProposalV2/styles";

const GeneracForm = ({ proposalType }) => {
  const { generacForm, setGeneracForm } = useContext(BatteryContext);
  const {
    batteryModuleQty,

    batteryPPW,
    batteryRebate,
  } = generacForm;

  const changeInput = (e) => {
    if (e.target.name === "batteryPPW") {
      let updated = {
        ...generacForm,
        batteryPPW: e.target.value,
        batteryCost: +e.target.value * (+batteryModuleQty * 3.36) * 1000,
      };
      console.log(updated);
      setGeneracForm(updated);
    } else if (e.target.name === "batteryModuleQty") {
      let updated = {
        ...generacForm,
        // enphaseRebate: enphaseRebateDetermine(e.target.value),
        enphaseRebate: 0,
        batteryModuleQty: e.target.value,
        batteryCost: +batteryPPW * (+e.target.value * 3.36) * 1000,
      };
      console.log(updated);
      setGeneracForm(updated);
    } else {
      console.log({
        ...generacForm,
        [e.target.name]: e.target.value,
      });
      setGeneracForm({
        ...generacForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <TextFieldStyles
        type="number"
        name={"batteryModuleQty"}
        value={batteryModuleQty}
        onChange={(e) => changeInput(e)}
        label={"Battery Module Quantity"}
        autoComplete="off"
      />

      <TextFieldStyles
        type="number"
        name={"batteryPPW"}
        value={batteryPPW}
        onChange={(e) => changeInput(e)}
        label={"Battery PPW"}
        autoComplete="off"
      />
      <TextFieldStyles
        type="number"
        name={"batteryRebate"}
        value={batteryRebate}
        onChange={(e) => changeInput(e)}
        label={"Battery Rebate"}
        autoComplete="off"
      />
    </>
  );
};

export default GeneracForm;
