import {
  getEPC,
  adjustedEPC,
  getSolarITC,
  getUtilityEscalator,
  getMonthlyBill,
  getAnnualBill,
  getTotalConsumption,
  getSolarPayment,
  getLoanAmount,
  getSystemSize,
  solarPlusUtil,
  ifAnnual,
  pvGen,
} from "./Calculations";
import { whatSrec, annualELectricityCostM, handleSRECIncome } from "./Helpers";
import axios from "axios";

export const ProposalTypeSwitcher = (proposalType) => {
  if (
    proposalType === "Loan" ||
    proposalType === "GeneracLoan" ||
    proposalType === "JEALoan" ||
    proposalType === "DecoLoan"
  ) {
    return "Loan";
  } else if (proposalType === "Cash") {
    return "Cash";
  } else if (
    proposalType === "MSES" ||
    proposalType === "IGSPPA" ||
    proposalType === "SUNRUN"
  ) {
    return "IGSPPA";
  } else if (proposalType === "IGSLEASE") {
    return "IGSLEASE";
  } else {
    return "";
  }
};

export const insertSpaceAtCaps = (str) => {
  const insertSpace = str.replace(/([A-Z])/g, " $1").trim();
  const capitalizeFirst =
    insertSpace.charAt(0).toUpperCase() + insertSpace.slice(1);
  return capitalizeFirst;
};

export const prepPDFData = (
  otherValues,
  initialFormValues,
  monthValues,
  consumptionType,
  initialProposalData,
  austinEnergy,
  allPanels
) => {
  // dependencies / args needed
  // otherValues
  let propType = ProposalTypeSwitcher(otherValues.proposalType);
  let pdfData = {
    ...initialFormValues,
    ...otherValues,
    preparerName: initialFormValues.RepName,
    preparerEmail: initialFormValues.RepEmail,
    EPC: getEPC(otherValues),
    adjustedEPC: adjustedEPC(otherValues),
    solarLoanITC: getSolarITC(otherValues),
    SRECPrice: whatSrec(initialFormValues.State),
    utilityEscalator: getUtilityEscalator(initialFormValues.State),
    monthlyBill: getMonthlyBill(otherValues, monthValues, consumptionType),
    oldAnnualBill: parseFloat(
      getAnnualBill(otherValues, monthValues, consumptionType).toFixed(2)
    ),
    size: getSystemSize(otherValues, allPanels),
    wholesaleRate: 0.035,
    consumption: getTotalConsumption(otherValues, monthValues, consumptionType),

    srecs: handleSRECIncome(otherValues.production, initialFormValues.State),
    // ? handleSRECIncome(production / 1000, State)
    // : "Not an SREC State",
  };
  if (propType === "Loan") {
    pdfData = {
      ...pdfData,

      loanPayment: getSolarPayment(
        otherValues,
        initialFormValues,
        austinEnergy
      ),
      loanAmount: getLoanAmount(otherValues, initialFormValues, austinEnergy),
      solarSavings: JSON.stringify(
        annualELectricityCostM(solarPlusUtil(otherValues, monthValues) / 12)
      ),
    };
  }

  if (propType === "Cash") {
    pdfData = {
      ...pdfData,
      solarSavings: JSON.stringify(pvGen(initialProposalData, otherValues)),
      annualElectricityCost: ifAnnual(
        otherValues,
        monthValues,
        consumptionType
      ),
    };
  }

  if (propType === "IGSPPA" || propType === "IGSLEASE") {
    pdfData = {
      ...pdfData,

      solarSavings: JSON.stringify(
        annualELectricityCostM(solarPlusUtil(otherValues) / 12)
      ),
      annualSolarBill: otherValues.production * otherValues.solarRate,
    };
  }
  console.log("here is initial");
  console.log(initialFormValues);
  console.log(pdfData);
  return pdfData;
};

let pdfMock = { data: "Just Digital", status: 200 };

//MBFIX_NOW
export const getPFDUrl = (revision, localProd, state, propType) =>
  revision
    ? "https://1cgucv2fle.execute-api.us-east-1.amazonaws.com/latest/api/tenpercent"
    : `${localProd}/api/${
        state.toLowerCase() === "ma"
          ? "nj"
          : state.toLowerCase() === "nv" || state.toLowerCase() === "ga"
          ? "ca"
          : state.toLowerCase() === "az"
          ? "ny"
          : state.toLowerCase()
      }${propType.toLowerCase()}`;

export const generatePDF = async (
  dataToPost,
  revisionValues,
  revision,
  localProd
) => {
  if (
    dataToPost.proposalType === "GeneracLoan" ||
    dataToPost === "JEALoan" ||
    dataToPost === "DecoLoan"
  )
    return pdfMock;
  let formData = new FormData();
  if (!revision) {
    Object.keys(dataToPost).forEach((key) => {
      formData.append(key, dataToPost[key]);
    });
    formData.append("salesRepName", dataToPost.RepName);
    formData.append("salesRepEMail", dataToPost.RepEmail);
  }
  if (revision) {
    Object.keys(revisionValues).forEach((key) => {
      formData.append(key, revisionValues[key]);
    });
    formData.append("CustomerName", dataToPost.CustomerName);
    formData.append("Address", dataToPost.Address);
    formData.append("City", dataToPost.City);
    formData.append("State", dataToPost.State);
    formData.append("Zip", dataToPost.Zip);
    formData.append("newSize", dataToPost.size);
    formData.append("newPanels", dataToPost.panels);
    formData.append("newProduction", dataToPost.production);
    formData.append("newDesignUrl", dataToPost.designUrl);
    formData.append("solarPanel", dataToPost.solarPanel);
    formData.append("consumption", dataToPost.consumption);
    formData.append("oppnum", dataToPost.oppnum);
    formData.append("proposalType", dataToPost.proposalType);
    formData.append("designImageUpload", dataToPost.designImageUpload);
    console.log("-----MB-------");
    console.log("here are revisionValues in helper");
    console.log(dataToPost);
    console.log(revisionValues);
    console.log(formData);
    console.log("-----MB-------");
  }
  let url = getPFDUrl(
    revision,
    localProd,
    dataToPost.State,
    ProposalTypeSwitcher(dataToPost.proposalType)
  );

  try {
    const { data } = await axios.post(url, formData);
    return {
      data: data.data,
      status: data.status,
    };
  } catch (error) {
    console.log(error);
    return { data: [], error, status: 500 };
  }
};
export const addWorkVals = (data) =>
  data.reduce((prev, curr) => {
    delete curr.filePreview;

    prev.push({
      ...curr,
      estimatedCost:
        // eslint-disable-next-line
        parseInt(curr.estimatedCost) === NaN ? 0 : parseInt(curr.estimatedCost),
    });
    return prev;
  }, []);
