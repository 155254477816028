import React, { createContext, useState } from "react";

export const BatteryContext = createContext({});

const BatteryContextProvider = ({ children }) => {
  const [batteryFormValues, setBatteryFormValues] = useState({});

  const [formErrors, setFormErrors] = useState({
    CustomerName: "",
    oppnum: "",
    address: "",
    city: "",

    zip: "",

    installCost: "",
    productCost: "",
    finanncingCost: "",
    RepEmail: "",
  });

  const [otherBatteryValues, setOtherBatteryValues] = useState({
    // dropdown
    state: "",
  });

  const [generacForm, setGeneracForm] = useState({
    batteryModuleQty: "",
    batteryCost: "",
    batteryRebate: "",
    enphaseRebate: "0",
    batteryPPW: "",
  });

  return (
    <BatteryContext.Provider
      value={{
        batteryFormValues,
        setBatteryFormValues,
        otherBatteryValues,
        setOtherBatteryValues,
        formErrors,
        setFormErrors,
        generacForm,
        setGeneracForm,
      }}
    >
      {children}
    </BatteryContext.Provider>
  );
};

export default BatteryContextProvider;
