import React, { useContext } from "react";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import {
  TextFieldStyles,
  NonInputStyles,
} from "../../Refactored/StartProposalV2/styles";
import { Context } from "../../Context/ProposalContext";
import { getTotalConsumption } from "../../Refactored/StartProposalV2/Calculations";

const StyledMonthly = styled(TextField)`
  && {
    width: 31%;
    min-width: 31%;
    margin-right: 1%;
  }
`;

const StyledMonthsContainer = styled.div`
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
`;

export const Monthly = () => {
  const { otherValues, monthValues, setMonthVals, consumptionType } =
    useContext(Context);
  const handleMonthly = (e) => {
    if (consumptionType === "Monthly") {
      let monthIndex = monthValues.findIndex(
        (findMonth) => findMonth.month === e.target.name
      );

      let monthCopy = [...monthValues];
      monthCopy[monthIndex].consumption = +e.target.value;

      setMonthVals(monthCopy);
      // if (!otherValues.utilityRate) return;
      // was mutating state here, dont
      //return;
    }
  };

  return (
    <StyledMonthsContainer>
      {monthValues.map((month) => {
        return (
          <StyledMonthly
            key={month.month}
            type={"number"}
            name={month.month}
            value={month.consumption}
            label={month.month}
            onChange={(e) => handleMonthly(e)}
          ></StyledMonthly>
        );
      })}
      <NonInputStyles>
        {`Total Consumption -  ${getTotalConsumption(
          otherValues,
          monthValues,
          consumptionType
        )}`}
      </NonInputStyles>
    </StyledMonthsContainer>
  );
};
export const Daily = () => {
  const { otherValues, monthValues, setMonthVals, consumptionType } =
    useContext(Context);
  const handleDaily = (e) => {
    let monthIndex = monthValues.findIndex(
      (findMonth) => findMonth.month === e.target.name
    );

    let monthCopy = [...monthValues];
    monthCopy[monthIndex].consumption = e.target.value;

    setMonthVals(monthCopy);
    return;
  };

  return (
    <StyledMonthsContainer>
      {monthValues.map((month) => {
        return (
          <StyledMonthly
            key={month.month}
            type={"number"}
            name={month.month}
            value={month.consumption}
            label={month.month}
            onChange={(e) => handleDaily(e)}
          ></StyledMonthly>
        );
      })}
      <NonInputStyles>
        {" "}
        {`Total Consumption - ${getTotalConsumption(
          otherValues,
          monthValues,
          consumptionType
        )}`}
      </NonInputStyles>
    </StyledMonthsContainer>
  );
};

export const Annual = () => {
  const { otherValues, setOtherValues } = useContext(Context);
  const handleInputChange = (e) => {
    setOtherValues({
      ...otherValues,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <TextFieldStyles
      type="number"
      name={"consumption"}
      value={otherValues.consumption}
      onChange={(e) => handleInputChange(e)}
      label={"Consumption "}
      autoComplete="off"
      error={!otherValues.consumption}
    />
  );
};
