import React, { useState } from "react";
import {
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  FormControlStyles,
  SelectStyles,
  TextFieldStyles,
} from "../../Refactored/StartProposalV2/styles";
import { handleEmpowerChange } from "../../Refactored/StartProposalV2/Helpers";

const OtherLoanFields = ({ otherValues, setOtherValues }) => {
  const onChangeDownPayment = (e) => {
    let currRebate = otherValues.rebate ? otherValues.rebate : 0;
    let newLoanAmount = otherValues.EPC - e.target.value - currRebate;
    setOtherValues({
      ...otherValues,
      loanAmount: newLoanAmount,
      downPayment: e.target.value,
      loanPayment: (newLoanAmount * otherValues.paymentFactor).toFixed(2),
    });
  };

  return (
    <TextFieldStyles
      type="number"
      onChange={(e) => onChangeDownPayment(e)}
      name={"downPayment"}
      value={otherValues.downPayment}
      label={"Down Payment"}
      autoComplete="off"
      error={!otherValues.downPayment ? true : false}
    ></TextFieldStyles>
  );
};
export const Sunlight = ({ onChangeLoanTerm, otherValues, setOtherValues }) => {
  return (
    <>
      <FormControlStyles>
        <InputLabel> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) => onChangeLoanTerm(e, otherValues, setOtherValues)}
          name={"loanTerm"}
        >
          <MenuItem value={15}>15 Years</MenuItem>
          <MenuItem value={20}>20 Years</MenuItem>
          <MenuItem value={25}>25 Years</MenuItem>
        </SelectStyles>
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    </>
  );
};

export const GreenSky = ({ onChangeLoanTerm, otherValues, setOtherValues }) => {
  return (
    <>
      <FormControlStyles>
        <InputLabel> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) => onChangeLoanTerm(e, otherValues, setOtherValues)}
          name={"loanTerm"}
        >
          <MenuItem value={20}>20 Years</MenuItem>
        </SelectStyles>
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    </>
    // 20 yr - 5.99 % - pmtFActor .00588
  );
};

export const Dividend = ({ onChangeLoanTerm, otherValues, setOtherValues }) => {
  const [isKeyBank, setIsKeyBank] = useState(false);
  const onSetEmpower = () => {
    handleEmpowerChange(otherValues, setOtherValues, !isKeyBank);
    setIsKeyBank(!isKeyBank);
  };

  const onInterestChange = (e) => {
    const DividendKeyBank = [
      { term: 12, interest: 4.99, paymentFactor: 0.0067108 },
      { term: 20, interest: 4.49, paymentFactor: 0.0045636 },
      { term: 20, interest: 2.99, paymentFactor: 0.0039656 },
      { term: 25, interest: 3.99, paymentFactor: 0.0037928 },
      { term: 25, interest: 2.99, paymentFactor: 0.0033874 },
    ];
    e.preventDefault();
    console.log(otherValues);
    console.log(otherValues.loanTerm);
    console.log(e.target.value);
    let found = DividendKeyBank.find(
      (i) => i.term === otherValues.loanTerm && e.target.value === i.interest
    );
    // console.log({
    //   ...otherValues,
    //   interest: found.interest,
    //   paymentFactor: found.paymentFactor,
    // });
    setOtherValues({
      ...otherValues,
      interest: found.interest,
      paymentFactor: found.paymentFactor,
    });
  };
  return (
    <>
      <FormControlStyles>
        <InputLabel> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) =>
            onChangeLoanTerm(e, otherValues, setOtherValues, isKeyBank)
          }
          name={"loanTerm"}
        >
          <MenuItem value={12}>12 Years</MenuItem>
          <MenuItem value={20}>20 Years</MenuItem>
          <MenuItem value={25}>25 Years</MenuItem>
        </SelectStyles>
        {otherValues.loanTerm && (
          // <FormControlLabel
          //   control={
          //     <Switch checked={isKeyBank} onChange={() => onSetEmpower()} />
          //   }
          //   label={`Key Bank ${isKeyBank ? "Y" : "N"}`}
          // />
          <FormControlStyles>
            <InputLabel>Interest Rate</InputLabel>
            {otherValues.loanTerm === 12 ? (
              <SelectStyles
                name={"interest"}
                value={otherValues.interest}
                onChange={onInterestChange}
              >
                {/* <MenuItem value={2.99}>2.99</MenuItem>
                <MenuItem value={3.99}>3.99</MenuItem> */}
                <MenuItem value={4.99}>4.99</MenuItem>
              </SelectStyles>
            ) : otherValues.loanTerm === 20 ? (
              <SelectStyles
                name={"keybank"}
                value={otherValues.interest}
                onChange={onInterestChange}
              >
                <MenuItem value={4.49}>4.49</MenuItem>
                <MenuItem value={2.99}>2.99</MenuItem>
              </SelectStyles>
            ) : (
              <SelectStyles
                name={"keybank"}
                value={otherValues.interest}
                onChange={onInterestChange}
              >
                <MenuItem value={3.99}>3.99</MenuItem>
                <MenuItem value={2.99}>2.99</MenuItem>
              </SelectStyles>
            )}
          </FormControlStyles>
        )}
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    </>
    //12 yr - 4.99% interest - pmt factor 0.007467667
    //20 yr - 5.99% intereset - pmt factor 0.00549533
  );
};
