import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
  // TablePagination
} from "@material-ui/core";
import { ArrowUpward, ArrowDownward, UnfoldMore } from "@material-ui/icons";
//import SearchDropdown from "../../components/ProposalTable/SearchDropdown";
import { Wrapper } from "../../components/ProposalTable/ProposalTable";
import UserRow from "./UserRow";
import NewUser from "./NewUser";

const AdminPanel = props => {
  const tableHeaders = [
    { id: "1", label: "Agent Name", key: "proposalAgentName" },
    { id: "2", label: "Agent Email", key: "proposalAgentEmail" }
  ];

  const { allUserData } = props;

  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const [page, setPage] = React.useState(0);
  const [currSort, setCurrSort] = useState({ key: "", direction: "" });

  // utility functions
  const sortHandler = key => {
    if (!key) return;
    if (currSort.key === key) {
      let direction = !currSort.direction
        ? 1
        : currSort.direction === 1
        ? 2
        : "";
      setCurrSort({ ...currSort, direction });
    } else {
      setCurrSort({
        key,
        direction: 1
      });
    }
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  // handle sort arrow ui
  const currArrow = key => {
    if (key !== currSort.key || !currSort.direction) return <UnfoldMore />;
    if (key === currSort.key && currSort.direction === 1)
      return <ArrowDownward />;
    if (key === currSort.key && currSort.direction === 2)
      return <ArrowUpward />;
  };
  const availablePermisisons = [
    "ADMIN",
    "USER",
    "ASSIGN",
    "PERMISSIONUPDATE",
    "READ",
    "WRITE",
    "HOAONLY",
    "IMAGESONLY"
  ];

  //const tableData = adminFilters(users.users, search, currSort, currSearch);

  return (
    <Wrapper height={"70vh"}>
      <div className="searchBarWrapper">
        {/* <SearchDropdown
          currSearch={currSearch}
          setCurrSearch={setCurrSearch}
          search={search}
          setSearch={setSearch}
          relatedData={tableHeaders}
        /> */}
      </div>

      <Paper className="root">
        <div className="tableWrapper">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHeaders.map(column => (
                  <TableCell
                    key={column.id}
                    style={{
                      background: "black",
                      color: "white",
                      fontSize: "16px"
                    }}
                    onClick={() => sortHandler(column.key)}
                  >
                    <div className="headerSort">
                      {column.label}
                      {currArrow(column.key)}
                    </div>
                  </TableCell>
                ))}
                {availablePermisisons.map(permission => (
                  <TableCell
                    key={permission}
                    style={{
                      background: "black",
                      color: "white",
                      fontSize: "16px",
                      maxWidth: "100px"
                    }}
                  >
                    {permission === "PERMISSIONUPDATE"
                      ? "PERMISSION UPDATE"
                      : permission}
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    background: "black",
                    color: "white",
                    fontSize: "16px",
                    maxWidth: "100px"
                  }}
                >
                  Submit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUserData.map(user => (
                <UserRow
                  key={user.id}
                  user={user}
                  availablePermisisons={availablePermisisons}
                />
              ))}
              <NewUser availablePermisisons={availablePermisisons} />
            </TableBody>
          </Table>
        </div>
        {/* 
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // sActionsComponent={CustomPagination}
          count={allUserData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Wrapper>
  );
};

export default withRouter(AdminPanel);
