import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { SubdirectoryArrowLeft, ArrowBack } from "@material-ui/icons";
import { authProvider } from "../../Auth/authConfig";
import { withRouter } from "react-router";
//constants
import logoFromHeader from "../../assets/SEANPNG.png";
import { StyledHeader } from "./styles";

import Hammburger from "../Hambburger";
import { Context } from "../../Context/ProposalContext";

export const Header = withRouter((props) => {
  const {
    userValues: { name },
    setOtherValues,
  } = useContext(Context);
  const onGoBack = () => {
    setOtherValues((prevState) => ({
      ...prevState,
      rebate: 0,
      wholesaleRate: "",
      solarEscalator: "",
      additionalwork: [
        {
          type: "tree",
          notes: "",
          img: "",
          filePreview: "",
          required: "",
          estimatedCost: "",
        },
        {
          type: "trench",
          notes: "",
          img: "",
          filePreview: "",
          required: "",
          estimatedCost: "",
        },
      ],
    }));
    props.history.goBack();
  };

  // const [isActive, setActive] = useState(false)

  return (
    <StyledHeader>
      <AppBar className="navbar">
        <Toolbar className="toolbarLeft">
          <Hammburger hamburgertype={"elastic"} />
          {/* <Home
            className="homeButton"
            onClick={() => props.history.push("/")}
          /> */}

          <img className="imgHeader" src={logoFromHeader} alt="momentum-logo" />
        </Toolbar>
        <Toolbar className="toolbar">
          <span className="grow">
            {props.location.pathname.includes("startpropsal") && (
              <ArrowBack onClick={() => onGoBack()} />
            )}
            Proposals Dash (V - 6.0.3)
          </span>
          {props.location.pathname.includes("proposal") && (
            <div className="state-type"></div>
          )}

          <p>{name}</p>
          <SubdirectoryArrowLeft
            className="logout-button"
            onClick={() => authProvider.logout()}
          />
        </Toolbar>
      </AppBar>
    </StyledHeader>
  );
});
