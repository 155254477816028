import React from "react";
import { Public, FileCopy, CancelOutlined } from "@material-ui/icons";

import styled from "styled-components";
import DispatchProposalStart from "../../components/DIspatchProposalStart/DispatchProposalStart";

const StyledPropFound = styled.div`
  display: grid;

  border-radius: 6px;
  border: solid 2px lightgrey;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: white;
  width: 100%;
  /* height: 200px; */
  overflow: auto;
  grid-template-rows: repeat(auto-fill, 40px);
  min-height: 250px;
  grid-gap: 18px;
  /* padding: 12px; */

  .row {
    display: grid;
    /* grid-auto-flow: column; */
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-items: center;
    font-size: 14px;
    grid-gap: 4px;
    width: 100%;
    height: 100%;

    span {
      padding: 4px;
      border: solid #2accff 1px;
      border-radius: 4px;
      align-items: center;
      height: 100%;
      width: 100%;
      width: 96%;
      text-align: center;
      display: grid;
    }
    .bills {
      display: grid;
    }
  }
`;

const ProposalFound = ({ proposal }) => {
  console.log(proposal);

  return (
    <StyledPropFound>
      <div className="row">
        <span> Oppnum:</span>
        <span> {`${proposal.oppnum}`}</span>
      </div>
      <div className="row">
        <span> Rep Name:</span>
        <span> {`${proposal.RepName}`}</span>
      </div>
      <div className="row">
        <span> Rep Email</span>
        <span> {`${proposal.RepEmail}`}</span>
      </div>

      <div className="row">
        <span> Address:</span>
        <span> {`${proposal.Address}, ${proposal.Zip}`}</span>
      </div>
      <div className="row">
        <span> State: </span>
        <span> {proposal.State}</span>
      </div>

      <div className="row">
        <span> Draft #:</span>
        <span> {`${proposal.draftNum}`}</span>
      </div>

      <div className="row">
        <span> Prop Type</span>
        <span> {`${proposal.proposalType}`}</span>
      </div>

      <div className="row">
        <span> Proposal Reason: </span>
        <span> {`${proposal.proposalReason || "NA"}`}</span>
      </div>

      <div className="row">
        <span> PDF:</span>
        <span>
          <Link url={proposal.pdfUrl} Icon={FileCopy} />
        </span>
      </div>

      <div className="row">
        <span> Digital:</span>
        <span>
          <Link
            url={`https://proposalview.momentumsolar.app/${proposal.id}`}
            // url={`https://dev.momentumsolar.app/home/${proposal.id}`}
            Icon={Public}
          />
        </span>
      </div>

      <div className="row">
        <span> Dispatch New:</span>
        <span>
          <DispatchProposalStart data={proposal} revision={false} />
        </span>
      </div>

      <div className="row">
        <span> Bill Images (Click 2 Copy):</span>

        {proposal.pimg.length > 0
          ? proposal.pimg.map((bill) => (
              <div className="bills">
                <span>
                  <a target="_blank" rel="noopener noreferrer" href={bill}>
                    <img alt="bill" style={{ width: "50px" }} src={bill} />
                  </a>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(bill);
                    }}
                  >
                    Copy Link
                  </button>
                </span>
              </div>
            ))
          : "No Bill Image(s)"}
      </div>
    </StyledPropFound>
  );
};

export const Link = ({ url, Icon }) =>
  url === "error" || !url ? (
    <CancelOutlined style={{ color: "red" }} />
  ) : (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <Icon />
    </a>
  );

export const Copy = ({ url }) =>
  !url ? (
    <CancelOutlined style={{ color: "red" }} />
  ) : (
    <FileCopy
      onClick={() => {
        navigator.clipboard.writeText(url);
      }}
    />
  );

export default ProposalFound;
