import React, { useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { COMPLETED_PROPOSAL_SEARCH_QUERY } from "../../utils/GQL/CompletedProposalQuery";
import { TextField } from "@material-ui/core";

import ProposalFound from "./ProposalFound";

const StyledPropSearch = styled.div`
  display: grid;
  height: calc(100vh - 120px);

  justify-items: center;
  grid-template-rows: auto 120px 1fr;
  grid-gap: 16px;

  .form {
    border-radius: 6px;
    border: solid 2px lightgrey;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background: rgba(254, 255, 255, 0.7);
    /* width: 100%; */
    display: grid;
    justify-items: center;
    width: 50%;

    button {
    }
  }
`;

const FoundPropsStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  width: 100%;
  grid-gap: 20px;
  justify-content: center;
  overflow: auto;
`;

const SearchProp = () => {
  const [oppnum, setOppnum] = useState("");
  const [getProp, { loading, data, error, called }] = useLazyQuery(
    COMPLETED_PROPOSAL_SEARCH_QUERY,
    {
      variables: {
        oppnum: oppnum.toUpperCase(),
      },
    }
  );

  console.log({ data, error, loading, called });

  const submitForm = (e) => {
    e.preventDefault();

    console.log(oppnum.toUpperCase());
    getProp();
  };

  return (
    <StyledPropSearch>
      <h1>Search Oppnumber below for prop </h1>
      <form className="form" onSubmit={submitForm}>
        <TextField
          value={oppnum}
          label={"Oppnum"}
          onChange={(e) => setOppnum(e.target.value)}
        />
        <input className="submit-button" type="submit" />
      </form>
      {!loading && called && (
        <FoundPropsStyles>
          {data.completedProposals.map((prop) => (
            <ProposalFound proposal={prop} />
          ))}
        </FoundPropsStyles>
      )}
    </StyledPropSearch>
  );
};

export default SearchProp;
